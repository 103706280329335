import { throttle, hasOwn } from '@simpl/core/utils/core'
import i18n from '@simpl/core/plugins/i18n'

interface MousePos {
  x: number
  y: number
}

interface DragOverQueueItem {
  el: HTMLElement
  rect: ClientRect
  mousePos: MousePos
}

const SAFE_ZONE_MULTIPLIER = 3
const DROP_MARKER_MARGIN = 5

// eslint-disable-next-line no-use-before-define
let previousDragDragHandler: DragDropHandler = null!

let dropTargetMarker: HTMLElement = null!
let dropMarker: HTMLElement = null!

export class DragDropHandler {
  currentElement: HTMLElement = null!
  currentElementChangeFlag: boolean = false
  elementRect: ClientRect = null!
  countdown: number = 1
  dragOverQueue: DragOverQueueItem[] = []
  private _dropTargetRect: ClientRect = null!

  private _sibling: HTMLElement = null!
  private _insertOperation: string = null!

  private _level: number = 0

  private _boundMethods: Record<string, (...args: any[]) => any> = {}

  get placeholder () {
    if (!dropMarker) {
      dropMarker = this.dropTarget.ownerDocument.createElement('div')
      dropMarker.classList.add('drop-marker')
      this.dropTarget.ownerDocument.body.appendChild(dropMarker)
    }

    return dropMarker
  }

  get targetMarker () {
    if (!dropTargetMarker) {
      dropTargetMarker = this.dropTarget.ownerDocument.createElement('div')
      dropTargetMarker.classList.add('drop-target')

      this.dropTarget.ownerDocument.body.appendChild(dropTargetMarker)
    }

    return dropTargetMarker
  }

  constructor (
    private dropTarget: HTMLElement,
    private dragValidator: () => boolean | ((e: DragEvent) => boolean) | void,
    private onDrop: ((e: DragEvent, insertOperation?: string, sibling?: HTMLElement | null) => void) | (() => void),
    private tag?: string,
    private onDragOver?: () => void,
    private onDragLeave?: () => void,
    private calculatePosition: boolean = true,
    hotReloadFlag = '_hasDragDrop'
  ) {
    // Check init flag (required in dev/hot-reload mode)
    if ((this.dropTarget as any)[hotReloadFlag]) return
    ;(this.dropTarget as any)[hotReloadFlag] = true

    this._boundMethods = {
      dragenter: (e) => this._onDragEnter(e),
      dragover: (e) => this._onDragOver(e),
      dragleave: () => this._onDragLeave(),
      dragend: () => this._endDrag(),
      drop: (e) => this._onDrop(e)
    }

    this.start()
  }

  start () {
    this.dropTarget.addEventListener('dragenter', this._boundMethods.dragenter, true)
    this.dropTarget.addEventListener('dragover', this._boundMethods.dragover)
    this.dropTarget.addEventListener('dragleave', this._boundMethods.dragleave)
    this.dropTarget.addEventListener('dragend', this._boundMethods.dragend)
    this.dropTarget.addEventListener('drop', this._boundMethods.drop)
  }

  destroy () {
    this._endDrag()

    this.dropTarget.removeEventListener('dragenter', this._boundMethods.dragenter, true)
    this.dropTarget.removeEventListener('dragover', this._boundMethods.dragover)
    this.dropTarget.removeEventListener('dragleave', this._boundMethods.dragleave)
    this.dropTarget.removeEventListener('dragend', this._boundMethods.dragend)
    this.dropTarget.removeEventListener('drop', this._boundMethods.drop)
  }

  isValidDropTarget (e: DragEvent) {
    const value = (this.dragValidator as any)(e)
    return typeof value === 'undefined' || !!value
  }

  isWithinSafeZone (e: MouseEvent) {
    const sz = this._level * SAFE_ZONE_MULTIPLIER

    if (!this._dropTargetRect) {
      this._dropTargetRect = this.dropTarget.getBoundingClientRect && this.dropTarget.getBoundingClientRect()
    }

    if (!this._dropTargetRect) {
      return false
    }

    const offsetX = e.clientX - this._dropTargetRect.left
    const offsetY = e.clientY - this._dropTargetRect.top

    return offsetX > sz && this._dropTargetRect.width - offsetX > sz &&
      offsetY > sz && this._dropTargetRect.height - offsetY > sz
  }

  _onDragEnter (e: DragEvent) {
    this.currentElement = (e.target as HTMLElement)
    this.currentElementChangeFlag = true
    this.elementRect = this.currentElement.getBoundingClientRect()
    this._dropTargetRect = this.dropTarget.getBoundingClientRect && this.dropTarget.getBoundingClientRect()
    this.countdown = 1

    if (this.isValidDropTarget(e)) {
      const eAny = e as DragEvent & { level: number }
      eAny.level = hasOwn(eAny, 'level') ? eAny.level + 1 : 0
      this._level = eAny.level
    }
  }

  _onDragOver (e: DragEvent) {
    if (!this.isValidDropTarget(e)) {
      e.dataTransfer!.dropEffect = 'none'
      return
    }

    if (!this.isWithinSafeZone(e)) {
      this.onDragLeave && this.onDragLeave()
      return
    }

    if (this.calculatePosition && e.altKey) {
      e.dataTransfer!.dropEffect = 'copy'
    } else {
      e.dataTransfer!.dropEffect = 'move'
    }

    e.preventDefault()
    e.stopPropagation()

    if (previousDragDragHandler && previousDragDragHandler !== this) {
      previousDragDragHandler._endDrag()
    }
    previousDragDragHandler = this

    this.countdown += 1

    if (this.countdown % 15 > 0 && !this.currentElementChangeFlag) {
      return
    }

    this.currentElementChangeFlag = false

    if (this.isValidDropTarget(e)) {
      this.placeDropTargetMarker(this.tag || '')
    }

    if (!this.calculatePosition) {
      this.onDragOver && this.onDragOver()
      return
    }

    this.addToQueue(this.currentElement, this.elementRect, {
      x: e.clientX,
      y: e.clientY
    })
  }

  _onDrop (e: DragEvent) {
    if (!this.isValidDropTarget(e)) {
      this._endDrag()
      return
    }

    if (!this.isWithinSafeZone(e)) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    if (!this.calculatePosition) {
      this.onDrop(e)
    } else {
      this.onDrop(e, this._insertOperation, this._sibling)
    }
    this._endDrag()
  }

  _onDragLeave () {
    this.onDragLeave && this.onDragLeave()
    this._endDrag()
  }

  _endDrag () {
    this._sibling = null!
    this._insertOperation = null!
    this.dragOverQueue = []
    this.hideDropTargetMarker()
    this.hidePlaceholder()
  }

  addToQueue (el: HTMLElement, rect: ClientRect, mousePos: MousePos) {
    this.dragOverQueue.push({ el, rect, mousePos })
    this.processQueue()
  }

  processQueue = throttle(function (this: DragDropHandler) {
    const processing = this.dragOverQueue.pop()
    this.dragOverQueue = []

    if (!processing) return

    this.orchestrateDragDrop(processing.el, processing.rect, processing.mousePos)
  }, 100)

  getMouseBearingsPercentage (el: HTMLElement, rect: ClientRect, mousePos: MousePos) {
    rect = rect || el.getBoundingClientRect()

    return {
      x: ((mousePos.x - rect.left) / (rect.right - rect.left)) * 100,
      y: ((mousePos.y - rect.top) / (rect.bottom - rect.top)) * 100
    }
  }

  orchestrateDragDrop (el: HTMLElement, rect: ClientRect, mousePos: MousePos) {
    if (!el || !rect || !mousePos) return false

    const breakPointNumber = { x: 25, y: 25 }
    const mousePercents = this.getMouseBearingsPercentage(el, rect, mousePos)
    this.placeDropTargetMarker(this.tag || '')

    if (el === this.dropTarget) {
      this.placePlaceholder(el, 'append')
    } else {
      if ((mousePercents.x <= breakPointNumber.x) || (mousePercents.y <= breakPointNumber.y)) {
        const validElement: HTMLElement | undefined =
          this.findValidParent(el)

        this.decideBeforeAfter(validElement!, mousePercents, mousePos)
      } else if ((mousePercents.x >= 100 - breakPointNumber.x) || (mousePercents.y >= 100 - breakPointNumber.y)) {
        const validElement: HTMLElement | undefined =
          this.findValidParent(el)

        this.decideBeforeAfter(validElement!, mousePercents, mousePos)
      }
    }
  }

  findValidParent (el: HTMLElement) {
    if (el === this.dropTarget) return el

    while (el && el.parentElement !== this.dropTarget) {
      el = el.parentElement!
    }
    return el
  }

  decideBeforeAfter (el: HTMLElement, mousePercents: MousePos, mousePos?: MousePos) {
    if (mousePos) {
      mousePercents = this.getMouseBearingsPercentage(el, null!, mousePos)
    }

    const style = window.getComputedStyle(this.dropTarget)
    const vertical = (
      style.display === 'inline' ||
      style.display === 'inline-block' ||
      (style.display === 'flex' && style.flexDirection === 'row')
    )

    if (vertical) {
      return mousePercents.x < 50
        ? this.placePlaceholder(el, 'before')
        : this.placePlaceholder(el, 'after')
    } else {
      return mousePercents.y < 50
        ? this.placePlaceholder(el, 'before')
        : this.placePlaceholder(el, 'after')
    }
  }

  placePlaceholder (el: HTMLElement, position: 'before' | 'after' | 'append' | 'prepend') {
    const style = window.getComputedStyle(this.dropTarget)
    const rect = this.getElementRect(el)
    const vertical = (
      style.display === 'inline' ||
      style.display === 'inline-block' ||
      (style.display === 'flex' && style.flexDirection === 'row')
    )
    let lastChild: Element | null = null

    if (vertical) {
      this.placeholder.classList.remove('horizontal')
      this.placeholder.classList.add('vertical')
      this.placeholder.style.width = ''
      this.placeholder.style.top = `${rect.top + DROP_MARKER_MARGIN}px`
      this.placeholder.style.height = `${rect.height - (DROP_MARKER_MARGIN * 2)}px`

      switch (position) {
        case 'append':
          lastChild = el.children[el.children.length - 1]
          if (lastChild) {
            const childRect = lastChild.getBoundingClientRect()
            this.placeholder.style.left = `${childRect.right - DROP_MARKER_MARGIN}px`
          } else {
            this.placeholder.style.left = `${this.elementRect.left + DROP_MARKER_MARGIN}px`
          }
          break
        case 'after':
          this.placeholder.style.left = `${rect.right}px`
          break
        case 'prepend':
          this.placeholder.style.left = `${rect.left + DROP_MARKER_MARGIN}px`
          break
        case 'before':
          this.placeholder.style.left = `${rect.left}px`
          break
      }
    } else {
      this.placeholder.style.left = `${rect.left + DROP_MARKER_MARGIN}px`
      this.placeholder.style.width = `${rect.width - (DROP_MARKER_MARGIN * 2)}px`
      this.placeholder.style.height = ''

      switch (position) {
        case 'append':
          lastChild = el.children[el.children.length - 1]
          if (lastChild) {
            const childRect = lastChild.getBoundingClientRect()
            this.placeholder.style.top = `${childRect.bottom}px`
          } else {
            this.placeholder.style.top = `${this.elementRect.top + DROP_MARKER_MARGIN}px`
          }
          break
        case 'after':
          this.placeholder.style.top = `${rect.bottom}px`
          break
        case 'prepend':
          this.placeholder.style.top = `${rect.top + DROP_MARKER_MARGIN}px`
          break
        case 'before':
          this.placeholder.style.top = `${rect.top}px`
          break
      }
      this.placeholder.classList.add('horizontal')
      this.placeholder.classList.remove('vertical')
      // this.placeholder.style.width = `${el.getBoundingClientRect().width}px`
    }

    this._sibling = el
    this._insertOperation = position

    this.showPlaceholder()
  }

  showPlaceholder () {
    this.placeholder.style.display = ''
  }

  hidePlaceholder () {
    this.placeholder.style.display = 'none'
  }

  placeDropTargetMarker (name: string) {
    const sz = this._level * SAFE_ZONE_MULTIPLIER

    if (this._dropTargetRect.top < 30) {
      this.targetMarker.classList.add('bottom-label')
    } else {
      this.targetMarker.classList.remove('bottom-label')
    }

    this.targetMarker.dataset.name = i18n.t(`cms.names.${name}`) as string
    this.targetMarker.style.display = 'block'
    this.targetMarker.style.left = `${this._dropTargetRect.left + sz}px`
    this.targetMarker.style.top = `${this._dropTargetRect.top + sz}px`
    this.targetMarker.style.width = `${this._dropTargetRect.width - sz * 2}px`
    this.targetMarker.style.height = `${this._dropTargetRect.height - sz * 2}px`
  }

  hideDropTargetMarker () {
    this.targetMarker.style.display = 'none'
  }

  getElementRect (el: HTMLElement): ClientRect {
    const rect = el.getBoundingClientRect()

    return {
      left: rect.left,
      right: rect.left + rect.width,
      top: rect.top,
      bottom: rect.top + rect.height,
      width: rect.width,
      height: rect.height
    }
  }
}
