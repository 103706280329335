








































































import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import { ContentTree } from '@simpl/cms/types'
import { Content } from '@simpl/core/types/graphql'
import { DragAndDropExerciseOptions, DragItem, DropZone } from '../types'
import { TrackingVisualizationDataAdjustments } from 'packages/tracking-evaluation/types'
import CDragItem from './CDragItem.vue'
import CDropZone from './CDropZone.vue'
import DragAndDropPropertyEditor from '../property-editors/DragAndDropPropertyEditor.vue'
import TrackingPropertyEditor from '../property-editors/TrackingPropertyEditor.vue'
import EvaluationButton from './EvaluationButton.vue'
import Layout from '../mixins/Layout'
import CMSEditableTextWrapper from './CMSEditableTextWrapper'
import mixins from 'vue-typed-mixins'
import Trackable from '../mixins/Trackable'
import TrackingVisualizationCreation from '../mixins/TrackingVisualizationCreation'
import WithUserInput from '../mixins/WithUserInput'

export default mixins(Layout, Trackable, TrackingVisualizationCreation, WithUserInput).extend({
  name: 'CDragAndDrop',

  components: { CDragItem, CDropZone, EvaluationButton, CMSEditableTextWrapper },

  props: {
    content: Object as () => ContentTree,
    exerciseSettings: Object as () => DragAndDropExerciseOptions,
    alignment: {
      type: String,
      default: 'flex-row'
    },
    zonesAlignment: {
      type: String,
      default: 'flex-column'
    },
    itemsAlignment: {
      type: String,
      default: 'flex-column'
    },
    numberOfDragItems: {
      type: [Number, String],
      default: 2
    },
    numberOfDropZones: {
      type: [Number, String],
      default: 1
    },
    width: {
      type: [Number, String],
      default: 200
    },
    height: {
      type: [Number, String],
      default: 50
    }
  },

  data () {
    return {
      evaluated: false,
      correct: true,
      readyForEvaluation: false,
      dragItems: this.content.data!.properties!.exerciseSettings?.dragItems || [] as DragItem[],
      dropZones: this.content.data!.properties!.exerciseSettings?.dropZones || [] as DropZone[]
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    exerciseOptions: {
      get (): DragAndDropExerciseOptions {
        return this.content.data!.properties!.exerciseSettings || {}
      },
      set (v: DragAndDropExerciseOptions) {
        this.content.data!.properties!.exerciseSettings = v
      }
    },
    instantEvaluation (): boolean {
      return this.content.data!.properties!.exerciseSettings?.instantEvaluation
    }
  },

  watch: {
    numberOfDragItems: {
      immediate: true,
      handler (v) {
        this.handleItems(this.dragItems, parseInt(v), 'item')
        this.exerciseOptions = {
          ...this.exerciseOptions,
          dragItems: this.dragItems
        }
      }
    },
    numberOfDropZones: {
      immediate: true,
      handler (v) {
        this.handleItems(this.dropZones, parseInt(v), 'zone')
        this.exerciseOptions = {
          ...this.exerciseOptions,
          dropZones: this.dropZones
        }
      }
    },
    exerciseOptions: {
      deep: true,
      handler (v) {
        if (v) {
          this.dropZones = v.dropZones
        }
      }
    }
  },

  mounted () {
    this.initExerciseOptions()
  },

  methods: {
    getTextForTemporaryUserLanguage,

    handleItems (items: DragItem[] | DropZone[], numberOfItems: number, key: string) {
      if (items.length < numberOfItems) {
        for (let i = items.length; i < numberOfItems; i++) {
          items.push({
            id: i + 1,
            identifier: `${key}_${i + 1}`
          })
        }
      }

      if (items.length > numberOfItems) {
        items.splice(numberOfItems, (items.length - numberOfItems))
      }
    },
    evaluate () {
      const dropZones = this.$refs.dropZones as any[]
      const droppedItems = [] as Record<string, any>[]
      this.correct = true

      dropZones.forEach((zone: any) => {
        droppedItems.push({
          dropZone: zone.zone.identifier,
          droppedItem: zone.droppedItemIdentifier
        })

        if (!zone.evaluate()) {
          this.correct = false
        }
      })

      if (!this.editMode) {
        this.writeValue(droppedItems)
        this.setCompleted()
        this.setCorrect(this.correct)

        if (this.exerciseOptions?.points && this.correct) {
          this.setPoints(this.exerciseOptions.points)
        }
      }

      this.evaluated = true
    },
    canEvaluate () {
      if (!this.$refs.dropZones) return

      const dropZones = this.$refs.dropZones as any[]
      let canEvaluate = true

      dropZones.forEach((zone: any) => {
        if (!zone.droppedItemIdentifier) {
          canEvaluate = false
        }
      })

      if (canEvaluate && this.instantEvaluation) this.evaluate()

      this.readyForEvaluation = canEvaluate
    },
    initExerciseOptions () {
      const savedValue = this.readValue()

      if (savedValue) {
        const dropZones = this.$refs.dropZones as any[]

        dropZones?.forEach((zone, index) => {
          zone.droppedItemIdentifier = savedValue[index].droppedItem
        })

        this.evaluate()
      }

      if (!this.content.data!.properties.exerciseSettings) {
        this.content.data!.properties.exerciseSettings = {
          instantEvaluation: false,
          dragItems: this.dragItems,
          dropZones: this.dropZones
        }
      }
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="45" viewBox="0 0 62 45">
  <g id="CMS_DragDrop_small" transform="translate(0.291)">
    <rect id="Rechteck_464" data-name="Rechteck 464" width="46" height="8" rx="1.097" transform="translate(14.709 4)" fill="none" stroke="#6d7579" stroke-miterlimit="10" stroke-width="2"/>
    <rect id="Rechteck_465" data-name="Rechteck 465" width="46" height="8" rx="1.016" transform="translate(14.709 18)" fill="none" stroke="#6d7579" stroke-miterlimit="10" stroke-width="2"/>
    <rect id="Rechteck_466" data-name="Rechteck 466" width="46" height="8" rx="1" transform="translate(14.709 33)" fill="none" stroke="#6d7579" stroke-miterlimit="10" stroke-width="2"/>
    <path id="Pfad_2143" data-name="Pfad 2143" d="M159.634,107.928l-.1-11.508" transform="translate(-131.605 -68.15)" fill="none" stroke="#6d7579" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
    <path id="Pfad_2144" data-name="Pfad 2144" d="M157.7,88.047l2.629,4.459a.946.946,0,0,1-.807,1.427l-5.176.047a.947.947,0,0,1-.833-1.413l2.548-4.505A.946.946,0,0,1,157.7,88.047Z" transform="translate(-128.998 -64.397)" fill="#6d7579"/>
    <text id="_1" data-name="1" transform="translate(6.515 9)" fill="#6d7579" font-size="8" font-family="Kanit-Bold, Kanit" font-weight="700"><tspan x="0" y="0">1</tspan></text>
    <text id="_2" data-name="2" transform="translate(5.94 23.961)" fill="#6d7579" font-size="8" font-family="Kanit-Bold, Kanit" font-weight="700"><tspan x="0" y="0">2</tspan></text>
    <text id="_3" data-name="3" transform="translate(6.515 37.771)" fill="#6d7579" font-size="8" font-family="Kanit-Bold, Kanit" font-weight="700"><tspan x="0" y="0">3</tspan></text>
    <rect id="Rechteck_463" data-name="Rechteck 463" width="46" height="8" rx="1.016" transform="translate(-0.291 37)" fill="#6d7579"/>
  </g>
</svg>`,
    layout: {
      disabled: ['typography']
    },
    props () {
      return [{
        key: 'settings',
        properties: [
          {
            key: 'numberOfDragItems',
            type: 'number',
            trackingSensitive: true
          }, {
            key: 'itemsAlignment',
            type: 'button-toggle',
            values: [{
              key: 'flex-column',
              icon: 'mdi-dots-vertical'
            }, {
              key: 'flex-row',
              icon: 'mdi-dots-horizontal'
            }]
          }, {
            key: 'numberOfDropZones',
            type: 'number',
            trackingSensitive: true
          }, {
            key: 'zonesAlignment',
            type: 'button-toggle',
            values: [{
              key: 'flex-column',
              icon: 'mdi-dots-vertical'
            }, {
              key: 'flex-row',
              icon: 'mdi-dots-horizontal'
            }]
          }, {
            key: 'alignment',
            type: 'button-toggle',
            headline: this.$t('cms.properties.alignment'),
            values: [{
              key: 'flex-row',
              icon: 'mdi-flip-horizontal'
            }, {
              key: 'flex-column',
              icon: 'mdi-flip-vertical'
            }, {
              key: 'flex-row-reverse',
              icon: 'mdi-flip-horizontal',
              class: 'rotate180'
            }, {
              key: 'flex-column-reverse',
              icon: 'mdi-flip-vertical',
              class: 'rotate180'
            }]
          }, {
            key: 'width',
            type: 'size',
            unit: 'px'
          }, {
            key: 'height',
            type: 'size',
            unit: 'px'
          }]
      }, {
        key: 'exercise',
        properties: [{
          key: 'exerciseSettings',
          type: 'DragAndDropExerciseOptions',
          component: DragAndDropPropertyEditor,
          value: this.content
        }]
      }, {
        key: 'tracking',
        properties: [{
          key: 'trackingSettings',
          type: 'TrackingOptions',
          component: TrackingPropertyEditor
        }]
      }]
    },
    category: {
      key: 'interactive'
    },
    tracking: {
      getTestData (content?: Content): any[] {
        if (!content) return []

        const exerciseSettings = content.data!.properties.exerciseSettings
        const dragItems = exerciseSettings.dragItems
        const dropZones = exerciseSettings.dropZones
        const dragItemIdentifiers = dragItems.map((dragItem: DragItem) => dragItem.identifier)
        const series = [] as Record<string, any>[]
        dragItemIdentifiers.forEach((dragItemIdentifier: string) => {
          const length = dropZones.length
          const randomArray = Array.from({ length: length }, () => Math.floor(Math.random() * 10))
          return series.push({
            identifier: dragItemIdentifier,
            name: getTextForTemporaryUserLanguage(this.content, dragItemIdentifier),
            data: randomArray
          })
        })
        return series
      },

      parseTrackingForVisualization (tracking: Record<string, any>, content?: Content, adjustments?: TrackingVisualizationDataAdjustments): any[] {
        if (!content) return []

        const legend = adjustments?.legend
        const exerciseSettings = content.data!.properties.exerciseSettings
        const dragItems = exerciseSettings.dragItems
        const dropZones = exerciseSettings.dropZones
        const dragItemIdentifiers = dragItems.map((dragItem: DragItem) => dragItem.identifier)
        const dropZoneIdentifiers = dropZones.map((dropZone: DropZone) => dropZone.identifier)
        const series = [] as Record<string, any>[]
        dragItemIdentifiers.forEach((dragItemIdentifier: string, index: number) => {
          const length = dropZoneIdentifiers.length
          const emptyArray = new Array(length).fill(0)

          return series.push({
            identifier: dragItemIdentifier,
            name: legend ? legend[index] : getTextForTemporaryUserLanguage(content, dragItemIdentifier),
            data: emptyArray
          })
        })
        const trackingData = tracking.tracking_data
        trackingData.forEach((td: Record<string, any>) => {
          td.data.value.forEach((trackedItem: Record<string, any>) => {
            const currentSerie = series.find((serie: Record<string, any>) => {
              return serie.identifier === trackedItem.droppedItem
            })
            const currentIndex = dropZoneIdentifiers.findIndex((dropZoneIdentifier:string) => {
              return dropZoneIdentifier === trackedItem.dropZone
            })
            currentSerie!.data[currentIndex]++
          })
        })
        return series
      },

      parseTrackingForTable (tracking: Record<string, any>, content?: Content, categories?: string[] | number[]): any[] {
        const arr = [] as any[]
        tracking.seriesData.forEach((data: any, index: number) => {
          const name = categories?.[index] ? categories?.[index] : data.name
          arr.push([name, ...data.data])
        })
        return arr
      }
    },
    visualization: {
      distributable: false,
      diagramTypes: ['groupedBar'],
      getTextForCategories (content: Content): string[] {
        const dropZonesTexts = content.data.properties.exerciseSettings.dropZones.map((dropZone:Record<string, any>) =>
          getTextForTemporaryUserLanguage(content, dropZone.identifier))

        return dropZonesTexts
      },
      getTextForLegend (content: Content): string[] {
        const dragItemsTexts = content.data.properties.exerciseSettings.dragItems.map((dragItem:Record<string, any>) =>
          getTextForTemporaryUserLanguage(content, dragItem.identifier))

        return dragItemsTexts
      }
    }
  }
})
