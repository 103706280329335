












import Vue from 'vue'
import { getTextForUserLanguage, updateTargetTexts } from '@simpl/core/utils'
import { ContentTree } from '@simpl/cms/types'
import { scheduleUpdateQueue } from '../utils/update-queue'

export default Vue.extend({
  name: 'TextInputPropertyEditor',

  props: {
    content: Object as () => ContentTree,
    value: Array
  },

  methods: {
    getTextForUserLanguage,

    onChange (v: string, key: string) {
      updateTargetTexts(this.content, v, key, this.$store.state.cms.currentLanguageCode)
      scheduleUpdateQueue()
    }
  }
})
