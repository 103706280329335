



















































import Vue from 'vue'
import { DragAndDropExerciseOptions, DropZone, DragItem } from '../types'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { ContentTree } from '@simpl/cms/types'
import { scheduleUpdateQueue } from '../utils/update-queue'

export default Vue.extend({
  name: 'DragAndDropPropertyEditor',

  model: {},

  props: {
    value: Object as () => DragAndDropExerciseOptions,
    content: Object as () => ContentTree
  },

  computed: {
    valueProxy (): DragAndDropExerciseOptions {
      return this.value || {}
    },
    instantEvaluation: {
      get (): boolean {
        return this.valueProxy.instantEvaluation
      },
      set (v: boolean) {
        this.setValue('instantEvaluation', v)
      }
    },
    dropZones (): DropZone[] {
      return this.valueProxy.dropZones
    },
    dragItems (): DragItem[] {
      return this.valueProxy.dragItems
    },
    points: {
      get (): number | void {
        return this.valueProxy.points
      },
      set (v: string | undefined) {
        this.setValue('points', v ? parseFloat(v) : v)
      }
    }
  },

  watch: {
    'value.dropZones' () {
      scheduleUpdateQueue()
    }
  },

  methods: {
    getTextForUserLanguage,

    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    },
    getText (item: DragItem | DropZone, translationKey: string) {
      const text = getTextForUserLanguage(this.content, item.identifier, false)

      if (text) {
        return text.replace(/(<([^>]+)>)/gi, '')
      } else {
        return `${this.$t(`cms.properties.${translationKey}`)} ${item.id}`
      }
    }
  }
})
