var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"video-player__toolbar pa-0",staticStyle:{"top":"auto"},attrs:{"bottom":"","absolute":"","width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","height":"100%","disabled":_vm.disabled},on:{"click":_vm.playPause}},on),[_c('v-icon',[_vm._v(_vm._s(!_vm.playing || _vm.paused ? 'mdi-play' : 'mdi-pause'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( _vm.playing ? 'videoPlayer.action.pause' : 'videoPlayer.action.play')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","height":"100%","disabled":_vm.position === 0 || _vm.position === _vm.duration || _vm.disabled},on:{"click":function($event){return _vm.$emit('stop')}}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( 'videoPlayer.action.stop')))])]),(!_vm.$vuetify.breakpoint.xsOnly && !_vm.previewBreakpointXs)?_c('span',{staticClass:"d-flex fill-height flex-grow-0 flex-shrink-0 align-center pr-6",on:{"mouseenter":function($event){_vm.showVolume = true},"mouseleave":function($event){_vm.showVolume = false}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","height":"100%"},on:{"click":function($event){return _vm.$emit('update:volume', _vm.volume > 0 ? 0 : 0.5)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.volumeIcon))])],1)]}}],null,false,2770911050)},[_c('span',[_vm._v(_vm._s(_vm.$t( _vm.volume > 0 ? 'videoPlayer.action.mute' : 'videoPlayer.action.unmute')))])]),_c('v-expand-x-transition',[(_vm.showVolume)?_c('v-slider',{staticClass:"flex-shrink-0 flex-grow-0",staticStyle:{"width":"100px"},attrs:{"step":"0.01","min":"0","max":"1","hide-details":""},model:{value:(_vm.internalVolume),callback:function ($$v) {_vm.internalVolume=$$v},expression:"internalVolume"}}):_vm._e()],1)],1):_vm._e(),_c('v-slider',{class:{ 'ml-6': _vm.$vuetify.breakpoint.sm },attrs:{"value":_vm.internalPosition,"step":0,"max":_vm.duration,"disabled":_vm.disabled,"hide-details":""},on:{"mousedown":function($event){_vm.isSeeking = true},"input":_vm.onSeeking,"change":_vm.onSeeked}}),_c('span',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm._f("sec2time")(_vm.position,_vm.duration))+" "),(!_vm.$vuetify.breakpoint.xsOnly && !_vm.previewBreakpointXs)?_c('span',[_vm._v(" / "+_vm._s(_vm._f("sec2time")(_vm.duration,_vm.duration)))]):_vm._e()]),(_vm.fullscreenEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","height":"100%"},on:{"click":_vm.toggleFullscreen}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'))])],1)]}}],null,false,2315039667)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'videoPlayer.action.fullscreenOn')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }