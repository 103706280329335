import { createDragElementClone } from './create-dragged-element-copy'
import dispatchDragEvent from './dispatch-drag-event'

let dragElement = null! as HTMLElement
let originalTarget = null! as HTMLElement

export default function onTouchStart (e: TouchEvent) {
  e.preventDefault()
  e.stopPropagation()

  dispatchDragEvent('dragstart', e.targetTouches[0].target as HTMLElement, e)

  dragElement = createDragElementClone(e.targetTouches[0].target as HTMLElement)
  originalTarget = e.targetTouches[0].target as HTMLElement

  dragElement.style.position = 'absolute'
  dragElement.style.zIndex = '1000'

  const ownerDocument = (e.target as HTMLElement).ownerDocument
  const ownerWindow = ownerDocument.defaultView!

  ownerDocument.body.append(dragElement)

  const touchEvent = e.changedTouches[0]
  moveAt(touchEvent.pageX, touchEvent.pageY, dragElement)

  ownerWindow.addEventListener('touchmove', onTouchMove)
  ownerWindow.addEventListener('touchend', onTouchEnd)
}

const onTouchMove = function (e: TouchEvent) {
  if (!dragElement) return

  const touchEvent = e.changedTouches[0]
  moveAt(touchEvent.pageX, touchEvent.pageY, dragElement)
}

const onTouchEnd = function (e: TouchEvent) {
  if (!dragElement) return

  const ownerDocument = dragElement.ownerDocument!
  const ownerWindow = ownerDocument.defaultView!

  const touchedElements = ownerWindow.document.elementsFromPoint(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
  const dropZone = touchedElements.find((el: any) => el.id === 'drop-zone') as HTMLElement

  ownerWindow.removeEventListener('touchmove', onTouchMove)
  dragElement.parentElement!.removeChild(dragElement)
  dragElement = null!

  if (dropZone) {
    dispatchDragEvent('drop', dropZone, e)
  }
  dispatchDragEvent('dragend', originalTarget, e)
}

const moveAt = function (pageX: number, pageY: number, dragElement: HTMLElement) {
  dragElement.style.left = pageX - dragElement.offsetWidth / 2 + 'px'
  dragElement.style.top = pageY - dragElement.offsetHeight / 2 + 'px'
}
