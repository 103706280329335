

















import { ContentTree } from '@simpl/cms/types'
import mixins from 'vue-typed-mixins'
import Layout from '../mixins/Layout'
import AssetDragDrop from '../mixins/AssetDragDrop'

export default mixins(AssetDragDrop, Layout).extend({
  name: 'CLottiePlayer',

  components: {
    LottiePlayer: () => import('./LottiePlayer.vue')
  },

  props: {
    content: Object as () => ContentTree,
    url: String
  },

  data () {
    return {
      mediaType: 'application/json'
    }
  },

  computed: {
    lottieFileUrl (): string {
      return this.assetDragOverUrl || this.url
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" style="height: 50px; width: 50px;">
  <g id="auto_awesome_motion_black_24dp" transform="translate(-0.333 -0.333)">
    <rect id="Rechteck_1612" data-name="Rechteck 1612" width="46" height="46" transform="translate(0.333 0.333)" fill="none"/>
    <path id="Pfad_3646" data-name="Pfad 3646" d="M23.389,2H5.889A3.9,3.9,0,0,0,2,5.889v17.5a1.95,1.95,0,0,0,1.944,1.944h0a1.95,1.95,0,0,0,1.944-1.944V5.889h17.5a1.95,1.95,0,0,0,1.944-1.944h0A1.95,1.95,0,0,0,23.389,2Zm7.778,7.778h-17.5a3.9,3.9,0,0,0-3.889,3.889v17.5a1.95,1.95,0,0,0,1.944,1.944h0a1.95,1.95,0,0,0,1.944-1.944v-17.5h17.5a1.95,1.95,0,0,0,1.944-1.944h0A1.95,1.95,0,0,0,31.167,9.778ZM37,17.556H21.444a3.9,3.9,0,0,0-3.889,3.889V37a3.9,3.9,0,0,0,3.889,3.889H37A3.9,3.9,0,0,0,40.889,37V21.444A3.9,3.9,0,0,0,37,17.556Z" transform="translate(1.889 1.889)" fill="#6d7579"/>
  </g>
</svg>
`,
    category: {
      key: 'media'
    },
    props () {
      return []
    }
  }
})
