



















import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import createComponent from '../utils/create-component'
import { scheduleUpdateQueue } from '../utils/update-queue'
import ColumnsPropertyEditor from '../property-editors/ColumnsPropertyEditor.vue'

export default Vue.extend({
  name: 'CColumns',

  inject: {
    editMode: { default: false }
  },

  props: {
    content: Object as () => ContentTree,
    layout: {
      type: Array as () => number[],
      default: () => [4, 4, 4]
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  watch: {
    layout: {
      handler (v: number[]) {
        if (!this.editMode) return

        if (this.content.children.length < v.length) {
          const len = v.length - this.content.children.length
          for (let i = 0; i < len; i++) {
            createComponent(this, this.content, 'CColumn')
          }
        } else if (this.content.children.length > v.length) {
          for (let i = v.length; i < this.content.children.length; i++) {
            this.content.children.splice(v.length, this.content.children.length - v.length)
            scheduleUpdateQueue()
          }
        }
      },
      immediate: true
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 80">
  <defs>
    <style>
      .a {
        fill: gray;
      }

      .b {
        fill: #fff;
      }

      .c {
        fill: #b3b3b3;
      }
    </style>
  </defs>
  <path class="a" d="M117,3V77H3V3H117m1-3H2A2,2,0,0,0,0,2V78a2,2,0,0,0,2,2H118a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Z"/>
  <rect class="a" x="1" y="1" width="118" height="7"/>
  <circle class="b" cx="6" cy="4" r="1.5"/>
  <circle class="b" cx="11" cy="4" r="1.5"/>
  <circle class="b" cx="16" cy="4" r="1.5"/>
  <path class="c" d="M36,13H30v2h6V13ZM25,13H19v2h6V13ZM14,13H8v2h6V13Zm-4,5H8v6h2V18Zm0,11H8v6h2V29Zm0,11H8v6h2V40Zm0,11H8v6h2V51Zm0,11H8v6h2V62Zm5,8H9v2h6V70Zm11,0H20v2h6V70Zm10,0H31v2h5V70Zm0-10H34v6h2V60Zm0-11H34v6h2V49Zm0-11H34v6h2V38Zm0-11H34v6h2V27Zm0-11H34v6h2V16Z"/>
  <path class="c" d="M74,13H68v2h6V13ZM63,13H57v2h6V13ZM52,13H46v2h6V13Zm-4,5H46v6h2V18Zm0,11H46v6h2V29Zm0,11H46v6h2V40Zm0,11H46v6h2V51Zm0,11H46v6h2V62Zm5,8H47v2h6V70Zm11,0H58v2h6V70Zm10,0H69v2h5V70Zm0-10H72v6h2V60Zm0-11H72v6h2V49Zm0-11H72v6h2V38Zm0-11H72v6h2V27Zm0-11H72v6h2V16Z"/>
  <path class="c" d="M112,13h-6v2h6V13Zm-11,0H95v2h6V13ZM90,13H84v2h6V13Zm-4,5H84v6h2V18Zm0,11H84v6h2V29Zm0,11H84v6h2V40Zm0,11H84v6h2V51Zm0,11H84v6h2V62Zm5,8H85v2h6V70Zm11,0H96v2h6V70Zm10,0h-5v2h5V70Zm0-10h-2v6h2V60Zm0-11h-2v6h2V49Zm0-11h-2v6h2V38Zm0-11h-2v6h2V27Zm0-11h-2v6h2V16Z"/>
</svg>`,
    category: {
      key: 'layout',
      order: 3
    },
    props () {
      return [{
        key: 'layout',
        properties: [{
          key: 'layout',
          type: 'layout',
          component: ColumnsPropertyEditor
        }]
      }]
    }
  }
})
