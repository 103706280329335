



























import { ContentTree } from '@simpl/cms/types'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import Layout from '../mixins/Layout'
import mixins from 'vue-typed-mixins'
import CMSEditableTextWrapper from './CMSEditableTextWrapper'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

export default mixins(Layout).extend({
  name: 'CHeading',

  components: {
    CMSEditableTextWrapper
  },

  props: {
    content: Object as () => ContentTree,
    size: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      editing: false
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    userText (): string {
      if (this.editMode || this.translateMode) {
        return getTextForLanguageWithFallback(this.content, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode, 'text')
      } else {
        return getTextForTemporaryUserLanguage(this.content, 'text')
      }
    },
    selected (): boolean {
      return this.$store.state.cms.selectedComponentId === this.content.id
    }
  },

  watch: {
    size () {
      this.editing && (this.$refs.editor as HTMLElement).focus()
    }
  },

  methods: {
    onDblClick () {
      if (!this.editMode && !this.translateMode) return

      this.editing = true
      this.$nextTick(() => {
        (this.$refs.editor as any).focus()
      })
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.41 52.16">
  <defs>
    <style>
      .a {
        fill: gray;
      }

      .b {
        font-size: 18px;
        fill: #666;
        font-weight: 800;
      }
    </style>
  </defs>
  <rect class="a" x="0.41" y="24.16" width="104" height="4"/>
  <rect class="a" x="0.41" y="48.16" width="104" height="4"/>
  <rect class="a" x="0.41" y="36.16" width="79" height="4"/>
  <text class="b" transform="translate(0 15.4)">Heading</text>
</svg>`,
    category: {
      key: 'typography'
    },
    containerClass: ['overflow-hidden'],
    layout: {
      disabled: ['typography.size', 'typography.lineHeight']
    },
    props () {
      return [{
        key: 'typography',
        properties: [{
          key: 'size',
          type: 'radio-button-group',
          specialLabels: true,
          buttonWidth: 36,
          values: new Array(6).fill(null).map((_, index) => ({
            label: `H${index + 1}`,
            value: index + 1
          }))
        }]
      }]
    }
  }
})
