import type { Vue, VueConstructor } from 'vue/types/vue'
import { ContentTree } from '@simpl/cms/types'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { uuid } from './uuid'
import { scheduleUpdateQueue } from './update-queue'

export default function createComponent (
  context: Vue,
  content: ContentTree,
  tag: string,
  insertOperation = 'append',
  sibling: HTMLElement & { __vue__: any } | any | null = null,
  properties: Record<string, any> = {}
): ContentTree {
  const newContentObj = createNewContentObject(context, tag, content.id, properties)

  if (insertOperation === 'after' && sibling?.__vue__) {
    const siblingId = sibling.__vue__.content?.id
    const insertPos = content.children.findIndex((c) => String(c.id) === String(siblingId))
    content.children.splice(insertPos === -1 ? 0 : insertPos + 1, 0, newContentObj)
  } else if (insertOperation === 'before' && sibling?.__vue__) {
    const siblingId = sibling.__vue__.content?.id
    const insertPos = content.children.findIndex((c) => String(c.id) === String(siblingId))
    content.children.splice(insertPos === -1 ? 0 : insertPos, 0, newContentObj)
  } else if (insertOperation === 'prepend' || insertOperation === 'before') {
    if (!content.children) context.$set(content, 'children', [])

    content.children.unshift(newContentObj)
  } else {
    if (!content.children) context.$set(content, 'children', [])

    content.children.push(newContentObj)
  }

  context.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, true)

  scheduleUpdateQueue()

  return newContentObj
}

function createNewContentObject (
  context: Vue,
  tag: string,
  parentId: number | string,
  properties?: Record<string, any>
): ContentTree {
  const componentConstructor = context.$options.components![tag] as VueConstructor

  const result = {
    id: uuid(),
    type: 'component',
    isLocal: true,
    parent: {
      id: String(parentId as any)
    } as any,
    data: {
      tag,
      properties
    },
    children: [],
    properties: null!,
    texts: []
  } as ContentTree

  const templateChildren: ({ tag: string, properties?: object })[] =
    componentConstructor?.options.cms?.templateChildren || []

  result.children = templateChildren.map((c) => createNewContentObject(context, c.tag, result.id, c.properties))

  return result
}
