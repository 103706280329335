import Vue from 'vue'

export type DirectionDict = {
  top: any
  right: any
  bottom: any
  left: any
}

type DisplaySettings = {
  align: 'start' | 'center' | 'end'
  justify: 'start' | 'center' | 'end'
}

export type SizeSettings = {
  height: string
  minHeight: string
  maxHeight: string

  width: string
  minWidth: string
  maxWidth: string
}

export type TypographySettings = {
  size: string
  lineHeight: string

  color: string

  align: 'left' | 'center' | 'right' | 'justify'

  styles: Partial<{
    bold: boolean,
    italic: boolean
  }>
  decoration: 'none' | 'strikethrough' | 'underline' | 'overline'
}

export type LayoutSettings = {
  padding?: Partial<DirectionDict>
  margin?: Partial<DirectionDict>

  display?: Partial<DisplaySettings>

  size?: Partial<SizeSettings>

  typography?: Partial<TypographySettings>
}

export default Vue.extend({
  props: {
    layout: Object as () => LayoutSettings
  },

  computed: {
    layoutClasses () {
      const classes: Record<string, boolean> = {}

      if (!this.layout) return classes

      composeSpacing(classes, this.layout)
      composeLayoutDisplay(classes, this.layout)

      return classes
    },

    layoutStyles () {
      const style: Partial<CSSStyleDeclaration> = {}

      if (!this.layout) return style

      composeSizeStyles(style, this.layout)
      composeTypographyStyles(style, this.layout)

      return style
    }
  }
})

function composeSpacing (classes: Record<string, boolean>, layout: Partial<LayoutSettings>) {
  for (const key of ['top', 'right', 'bottom', 'left']) {
    if (typeof (layout?.margin as any)?.[key] !== 'undefined') {
      classes[`m${key.substr(0, 1)}-${String((layout!.margin as any)[key]).replace('-', 'n')}`] = true
    }

    if (typeof (layout?.padding as any)?.[key] !== 'undefined') {
      classes[`p${key.substr(0, 1)}-${(layout.padding as any)[key]}`] = true
    }
  }
}

function composeLayoutDisplay (classes: Record<string, boolean>, layout: Partial<LayoutSettings>) {
  if (layout?.display?.align || layout?.display?.justify) {
    classes['d-flex'] = true
    classes['flex-column'] = true

    if (layout?.display?.align) {
      classes[`align-${layout.display.align}`] = true
    }

    if (layout?.display?.justify) {
      classes[`justify-${layout.display.justify}`] = true
    }
  }
}

function composeSizeStyles (styles: Partial<CSSStyleDeclaration>, layout: Partial<LayoutSettings>) {
  if (layout?.size?.width) {
    styles.width = layout.size.width
  }
  if (layout?.size?.minWidth) {
    styles.minWidth = layout.size.minWidth
  }
  if (layout?.size?.maxWidth) {
    styles.maxWidth = layout.size.maxWidth
  }

  if (layout?.size?.height) {
    styles.height = layout.size.height
  }
  if (layout?.size?.minHeight) {
    styles.minHeight = layout.size.minHeight
  }
  if (layout?.size?.maxHeight) {
    styles.maxHeight = layout.size.maxHeight
  }
}

function composeTypographyStyles (styles: Partial<CSSStyleDeclaration>, layout: Partial<LayoutSettings>) {
  if (layout?.typography?.size) {
    styles.fontSize = layout.typography.size
  }
  if (layout?.typography?.lineHeight) {
    styles.lineHeight = layout.typography.lineHeight
  }
  if (layout?.typography?.color) {
    styles.color = layout.typography.color
  }
}
