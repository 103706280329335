import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { hasOwn } from '@simpl/core/utils/core'
import { nameToIdentifier } from '@simpl/core/utils'
import { CREATE_TRACKING_VISUALIZATION } from '@simpl/tracking-evaluation/graphql'
import initializeHeadlines from '../utils/initialize-headlines'

export default Vue.extend({
  name: 'TrackingVisualizationCreation',

  props: {
    content: Object as () => ContentTree
  },

  data () {
    return {
      internalId: '',
      internalHeadlineId: -1,
      internalHeadlines: [] as Record<string, any>[],
      isCreationRequested: false
    }
  },

  computed: {
    headlineId: {
      get (): number {
        return this.internalHeadlineId
      },
      set (v: number) {
        this.internalHeadlineId = v
      }
    },
    headlines: {
      get (): Record<string, any>[] {
        return this.internalHeadlines
      },
      set (v: Record<string, any>[]) {
        this.internalHeadlines = v
      }
    },

    selectedContent (): ContentTree {
      return this.$store.getters['cms/selectedContent']
    }
  },

  mounted () {
    this.internalId = this.content.id
  },

  beforeDestroy () {
    if (
      !hasOwn(this.content, 'isLocal') &&
      this.content.id !== this.internalId
    ) {
      // to prevent multiple mutations
      !this.isCreationRequested && this.createTrackingVisualization()
    }
  },

  methods: {
    getTrackingVisualizationMutationData (): { data: Record<string, any> } {
      const tag = this.content.data?.tag
      const visualizationType = tag === 'CTextInput' ? 'wordcloud' : 'graph'
      const identifier = `${tag} ${this.content.id}`
      const data = {
        identifier: nameToIdentifier(identifier),
        type: visualizationType,
        items: {
          create: [{
            module: {
              connect: this.$store.state.cms.moduleId
            },
            objective: String(this.$store.state.cms.selectedSiteId),
            site: String(this.$store.state.cms.selectedSiteId),
            key: String(this.content.id),
            component_tag: String(tag)
          }]
        }
      }
      return { data }
    },

    setTrackingSettings (trackingVisualizationItemId: number) {
      const initializedHeadlines = initializeHeadlines(
        this.selectedContent,
        this.content.id,
        this.headlineId
      )

      this.headlines = initializedHeadlines.headlines
      this.headlineId = initializedHeadlines.headlineId

      const trackingSettings = {
        headlineId: this.headlineId,
        trackingVisualizationItemId: trackingVisualizationItemId
      }
      this.content.data!.properties.trackingSettings = trackingSettings
    },

    async createTrackingVisualization () {
      this.isCreationRequested = true
      const variables = this.getTrackingVisualizationMutationData()
      const answer = await this.$apollo.mutate({
        mutation: CREATE_TRACKING_VISUALIZATION,
        variables: variables
      })

      if (answer.data?.createTrackingVisualization?.items?.[0].id) {
        this.setTrackingSettings(answer.data?.createTrackingVisualization?.items?.[0].id)
      }
    }
  }
})
