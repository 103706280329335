import { ContentTree } from '@simpl/cms/types'

export default function initializeHeadlines (
  content:ContentTree,
  contentId:string,
  headlineId:number
): {
  headlines: ContentTree[],
  headlineId: number
} {
  let newHeadlineId = headlineId

  const allHeadlines = [] as ContentTree[]
  const traverse = (item: ContentTree): any => {
    if (item.data?.tag === 'CHeading') {
      allHeadlines.push(item)
    } else if (item.id === contentId) {
      const headline = allHeadlines[allHeadlines.length - 1]
      if (newHeadlineId < 0) {
        newHeadlineId = parseInt(headline?.id)
      }
    }
    item.children.map(traverse)
  }
  traverse(content)

  return {
    headlines: allHeadlines,
    headlineId: newHeadlineId
  }
}
