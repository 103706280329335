export default function dispatchDragEvent (
  dragEvent: string,
  targetElement: HTMLElement,
  touchEvent: TouchEvent,
  dataTransfer?: DataTransfer,
  cancelable = true,
  relatedTarget: HTMLElement | null = null
) {
  const event = createDragEventFromTouch(targetElement, touchEvent, dragEvent,
    cancelable, (document as any).defaultView, dataTransfer, relatedTarget)

  targetElement.dispatchEvent(event)
}

const createDragEventFromTouch = function (
  targetElement: HTMLElement,
  e: TouchEvent,
  type: string,
  cancelable: boolean,
  window: Window,
  dataTransfer?: DataTransfer,
  relatedTarget: HTMLElement | null = null
) {
  const touch = e.changedTouches[0]

  const dragEvent: any = new Event(type, {
    bubbles: true,
    cancelable: cancelable
  }) as DragEvent

  dragEvent.dataTransfer = dataTransfer as any
  dragEvent.relatedTarget = relatedTarget

  dragEvent.screenX = touch.screenX
  dragEvent.screenY = touch.screenY
  dragEvent.clientX = touch.clientX
  dragEvent.clientY = touch.clientY
  dragEvent.pageX = touch.pageX
  dragEvent.pageY = touch.pageY

  const targetRect = targetElement.getBoundingClientRect()
  dragEvent.offsetX = dragEvent.clientX - targetRect.left
  dragEvent.offsetY = dragEvent.clientY - targetRect.top

  return dragEvent
}
