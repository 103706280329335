

































































































import { ContentTree } from '@simpl/cms/types'
import CMSEditableTextWrapper from './CMSEditableTextWrapper'
import ui from '@simpl/core/plugins/ui'
import { getTextForLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import SingleMultipleChoicePropertyEditor from '../property-editors/SingleMultipleChoicePropertyEditor.vue'
import TrackingPropertyEditor from '../property-editors/TrackingPropertyEditor.vue'
import { SingleMultipleChoiceExerciseOptions, TrackingOptions } from '../types'
import EvaluationButton from './EvaluationButton.vue'
import Trackable from '../mixins/Trackable'
import TrackingVisualizationCreation from '../mixins/TrackingVisualizationCreation'
import Layout from '../mixins/Layout'
import mixins from 'vue-typed-mixins'
import WithUserInput from '../mixins/WithUserInput'
import { Content } from '@simpl/core/types/graphql'

export default mixins(Layout, Trackable, TrackingVisualizationCreation, WithUserInput).extend({
  name: 'CSingleMultipleChoice',

  components: {
    CMSEditableTextWrapper,
    EvaluationButton
  },

  props: {
    content: Object as () => ContentTree,
    selectionType: {
      type: String,
      default: 'single'
    },
    answers: {
      type: Array,
      default: () => []
    },
    numberOfAnswers: {
      type: [Number, String],
      default: 2
    },
    exerciseSettings: Object as () => SingleMultipleChoiceExerciseOptions,
    trackingSettings: Object as () => TrackingOptions
  },

  data () {
    return {
      initialized: false,
      evaluated: false,
      correct: null as boolean | null,
      selected: null! as string | string[]
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms?.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    internalAnswers () {
      return this.content.data!.properties.answers
    },
    canEvaluate (): boolean {
      return !!this.selected?.length && !!this.content.data!.properties.exerciseSettings.solution
    },
    componentSelected (): boolean {
      return this.$store.state.cms?.selectedComponentId === this.content.id
    },
    isSingleChoice (): boolean {
      return this.content.data!.properties.selectionType === 'single'
    },
    exerciseOptions: {
      get (): Record<string, any> {
        return this.content.data!.properties.exerciseSettings || {}
      },
      set (v: Record<string, any>) {
        this.content.data!.properties.exerciseSettings = v
      }
    },
    wrong (): string[] {
      const solution = Array.isArray(this.exerciseOptions.solution)
        ? this.exerciseOptions.solution
        : [this.exerciseOptions.solution]

      return this.internalAnswers
        .filter((answer: Record<string, any>) => solution.indexOf(answer.id) < 0)
        .map((answer: Record<string, any>) => answer.id)
    }
  },

  watch: {
    isSingleChoice: {
      immediate: true,
      handler () {
        this.reset()
        this.exerciseOptions = {
          ...this.content.data!.properties.exerciseSettings,
          type: this.content.data!.properties.selectionType
        }
      }
    },
    internalAnswers: {
      deep: true,
      handler (v) {
        this.exerciseOptions = {
          ...this.content.data!.properties.exerciseSettings,
          allAnswers: v.map(this.remapAnswers)
        }
      }
    },
    editMode (v) {
      if (v) {
        this.reset()
      }
    },
    selected: {
      immediate: true,
      handler (v) {
        if (this.editMode || this.translateMode) return

        if (!this.exerciseOptions.isExercise && this.initialized) {
          this.writeValue(v)
          this.setCompleted(!!v?.length)
        }
      }
    },
    numberOfAnswers: {
      immediate: true,
      handler (v) {
        const answers = this.content.data!.properties.answers // .slice()

        if (answers.length < v) {
          for (let i = answers.length; i < v; i++) {
            answers.push({
              id: `answer_${i + 1}`
            })
          }
        } else {
          answers.splice(parseInt(v as string), answers.length - parseInt(this.numberOfAnswers as string))
        }
      }
    }
  },

  mounted () {
    this.initExerciseOptions()
  },

  methods: {
    getTextForLanguage,
    getTextForTemporaryUserLanguage,
    evaluate () {
      if (this.isSingleChoice) {
        this.correct = this.selected === this.exerciseOptions.solution
      } else {
        if (this.exerciseOptions.solution.length !== this.selected!.length) {
          this.correct = false
        } else {
          this.correct = true

          ;(this.selected! as string[]).forEach((selection: string) => {
            if (this.exerciseOptions.solution.indexOf(selection) < 0) {
              this.correct = false
            }
          })
        }
      }

      this.evaluated = true

      if (!this.editMode) {
        this.setCompleted()
        this.setCorrect(this.correct)
        if (this.exerciseOptions?.points) {
          let points = 0
          if (this.correct) {
            points = this.exerciseOptions.points
            this.setPoints(points)
          }
          this.writeValue(this.selected, points)
        } else {
          this.writeValue(this.selected)
        }
      }
    },
    reset () {
      this.correct = null
      this.evaluated = false
      this.selected = this.isSingleChoice ? '' : []
    },
    initExerciseOptions () {
      this.reset()

      if (this.readValue()) {
        this.selected = this.readValue()
        if (this.exerciseOptions?.isExercise || this.showEvaluation) {
          this.evaluate()
        }
      }

      if (!Object.keys(this.exerciseOptions).length) {
        this.exerciseOptions = {
          isExercise: this.$store.state.cms.isTest,
          type: this.content.data!.properties.selectionType,
          allAnswers: this.content.data!.properties.answers.map(this.remapAnswers),
          solution: this.isSingleChoice ? '' : []
        }
      }

      if (this.$store.state.cms.isTest) {
        this.exerciseOptions = {
          ...this.exerciseOptions,
          isExercise: true
        }
      }
      this.initialized = true
    },
    remapAnswers (answer: Record<string, any>): Record<string, any> {
      return {
        id: answer.id,
        text: getTextForLanguage(this.content, this.$store.state.cms.currentLanguageCode, answer.id),
        textKey: 'answer',
        textKeySuffix: answer.id.split('_')[1]
      }
    },
    updateAnswerTexts (text: string, answerId: string) {
      const updatedAnswers = this.exerciseOptions.allAnswers.reduce((acc: Record<string, any>[], answer: Record<string, any>) => {
        if (answer.id === answerId) {
          acc.push({
            ...answer,
            text: text
          })
        } else {
          acc.push(answer)
        }
        return acc
      }, [])

      this.exerciseOptions = {
        ...this.content.data!.properties.exerciseSettings,
        allAnswers: updatedAnswers
      }
    }
  },

  cms: {
    preview () {
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 58">
  <defs>
    <style>
      .c-choice--a {
        fill: none;
        stroke: gray;
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }

      .c-choice--b {
        fill: gray;
      }

      .c-choice--c {
        fill: ${ui.framework.theme.currentTheme.primary};
      }
    </style>
  </defs>
  <rect class="c-choice--a" x="1" y="1" width="14" height="14"/>
  <rect class="c-choice--b" x="22" y="5" width="69" height="6"/>
  <rect class="c-choice--a" x="1" y="43" width="14" height="14"/>
  <rect class="c-choice--b" x="22" y="26" width="65" height="6"/>
  <rect class="c-choice--c" y="21" width="16" height="16"/>
  <rect class="c-choice--b" x="22" y="47" width="72" height="6"/>
</svg>`
    },
    layout: {
      disabled: ['typography']
    },
    props () {
      return [{
        key: 'settings',
        properties: [{
          key: 'selectionType',
          type: 'radio',
          values: ['single', 'multiple'],
          trackingSensitive: true
        }, {
          key: 'numberOfAnswers',
          type: 'number',
          trackingSensitive: true
        }]
      }, {
        key: 'exercise',
        properties: [{
          key: 'exerciseSettings',
          type: 'SingleMultipleChoiceExerciseOptions',
          component: SingleMultipleChoicePropertyEditor
        }]
      }, {
        key: 'tracking',
        properties: [{
          key: 'trackingSettings',
          type: 'TrackingOptions',
          component: TrackingPropertyEditor
        }]
      }]
    },
    category: {
      key: 'interactive'
    },
    tracking: {
      parseTrackingForVisualization (tracking: Record<string, any>, content?: Content): any[] {
        const properties = content?.data?.properties ? content.data?.properties : tracking.properties?.properties
        const trackingData = tracking.tracking_data
        const answers = properties.answers
        if (answers.length === 0) {
          return []
        }
        const arr = new Array(answers.length).fill(0)
        if (properties.selectionType === 'single') {
          trackingData.forEach((td: Record<string, any>) => {
            const value = td.data.value
            const index = answers.findIndex((answer: { id: string }) => answer.id === value)
            arr[index]++
          })
          return arr
        } else {
          trackingData.forEach((td: Record<string, any>) => {
            const values = td.data.value
            values.forEach((value: string) => {
              const index = answers.findIndex((answer: { id: string }) => answer.id === value)
              arr[index]++
            })
          })
          return arr
        }
      },
      parseTrackingForTable (tracking: Record<string, any>, content?: Content, categories?: string[] | number[]): any[] {
        const arr = [] as any[]
        let category = ''
        let key = ''
        let participations = 0
        let percentage = 0
        let answers = [] as { id: string }[]
        tracking.seriesData.forEach((data: number) => {
          participations += data
        })
        if (content) {
          answers = content?.data.properties.answers
          tracking.seriesData.forEach((data: any, index: number) => {
            key = answers[index].id
            category = categories ? String(categories[index]) : getTextForTemporaryUserLanguage(content, key)
            percentage = participations && data / participations
            arr.push([category, data, percentage])
          })
        }
        return arr
      }
    },
    visualization: {
      diagramTypes: ['bar', 'line', 'pie', 'donut'],
      getTextForCategories (content: Content): string[] {
        return content.data.properties.answers.map((answer:Record<string, any>) =>
          getTextForTemporaryUserLanguage(content, answer.id))
      }
    }
  }
})
