




























import Vue from 'vue'

export default Vue.extend({
  name: 'SplitResizeView',

  props: {
    sizes: {
      type: Array as () => number[],
      required: true
    },
    step: Number,
    min: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      rect: null! as DOMRect,
      resizerIndex: 0,
      startSizes: [0, 0],
      startX: 0 as number,
      dragActive: false
    }
  },

  computed: {
    maxValue (): number {
      return this.sizes.reduce((a, v) => a + v)
    }
  },

  methods: {
    onMouseDown (e: MouseEvent, index: number) {
      this.resizerIndex = index
      this.startSizes = this.sizes.slice(index, index + 2)
      this.rect = (this.$el as HTMLElement).getBoundingClientRect()
      this.startX = e.clientX
      this.dragActive = true

      window.addEventListener('mousemove', this.onMouseMove)
      window.addEventListener('mouseup', this.onMouseUp)
    },
    onMouseMove (e: MouseEvent) {
      const xDiff = e.clientX - this.startX
      const stepDiff = Math.round(xDiff / this.rect.width * this.maxValue)

      const newSizeLeft = this.startSizes[0] + stepDiff
      const newSizeRight = this.startSizes[1] - stepDiff

      if (newSizeLeft <= 0 || newSizeRight <= 0) {
        return
      }

      const newSizes = this.sizes.slice()
      newSizes[this.resizerIndex] = newSizeLeft
      newSizes[this.resizerIndex + 1] = newSizeRight
      this.updateSizes(newSizes)
    },
    onMouseUp (e: MouseEvent) {
      const xDiff = e.clientX - this.startX
      const stepDiff = Math.round(xDiff / this.rect.width * this.maxValue)

      const newSizeLeft = this.startSizes[0] + stepDiff
      const newSizeRight = this.startSizes[1] - stepDiff

      if (newSizeLeft <= 0 || newSizeRight <= 0) {
        return
      }

      const newSizes = this.sizes.slice()
      newSizes[this.resizerIndex] = newSizeLeft
      newSizes[this.resizerIndex + 1] = newSizeRight
      this.updateSizes(newSizes)

      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)

      this.dragActive = false
    },

    updateSizes (sizes: number[]) {
      this.$emit('update:sizes', sizes)
    }
  }
})
