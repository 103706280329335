






























import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'EvaluationButton',

  props: {
    evaluated: Boolean,
    correct: Boolean,
    disabled: Boolean,
    alignRight: Boolean,
    text: [String, Object as () => TranslateResult]
  },

  computed: {
    showEvaluation (): boolean {
      return this.$store.getters['module/showTestEvaluation']
    },
    buttonText (): TranslateResult {
      return !this.showEvaluation
        ? this.evaluated ? this.$t('cms.exercise.sent') : this.$t('cms.exercise.send')
        : this.$t('cms.singleMultipleChoice.evaluate')
    },
    buttonColor (): string {
      return (this.evaluated && !this.$store.state.module.isTest) || this.showEvaluation
        ? this.correct ? 'success' : 'error'
        : !this.showEvaluation && this.evaluated ? '' : 'primary'
    }
  }
})
