const addCloneElementStyles = function (srcElement: HTMLElement, clone: HTMLElement) {
  const computedStyles = getComputedStyle(srcElement)

  for (let i = 0; i < computedStyles.length; i++) {
    const styleName = computedStyles[i]
    switch (styleName) {
      case 'color':
      case 'column-rule-color':
      case 'fill':
      case '-webkit-text-stroke-color':
      case '-webkit-text-fill-color':
        clone.style.setProperty(styleName, 'unset', computedStyles.getPropertyPriority(styleName))
        break
      default:
        clone.style.setProperty(styleName, computedStyles.getPropertyValue(styleName), computedStyles.getPropertyPriority(styleName))
    }
    clone.removeAttribute('id')
    clone.removeAttribute('class')
    clone.removeAttribute('draggable')
  }
}

export function createDragElementClone (srcElement: HTMLElement) {
  const cloneElement = srcElement.cloneNode(true) as HTMLElement

  addCloneElementStyles(srcElement, cloneElement)

  return cloneElement
}
