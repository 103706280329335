import { MUTATIONS } from '@simpl/cms/store/consts'
import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import EditableText from './EditableText.vue'
import store from '@simpl/core/plugins/store'

export default Vue.extend({
  name: 'CMSEditableTextWrapper',

  functional: true,

  props: {
    item: Object as () => ContentTree,
    identifier: String,
    size: [String, Number]
  },

  render: (h, context
  ) => {
    const { props, listeners } = context
    const emitFocus = (listeners.focus || (() => {})) as any
    const emitBlur = (listeners.blur || (() => {})) as any

    return h(EditableText, {
      key: `cms-editable-text--id-${props.identifier}`,
      ref: 'editor',
      nativeOn: {
        click: (e: MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      on: {
        focus: (event: FocusEvent) => {
          setTimeout(() => {
            store.commit(`cms/${MUTATIONS.SET_CONTENT_DRAGGING_DISABLED}`, true)
            emitFocus(event)
          }, 300)
        },
        blur: (event: FocusEvent) => {
          store.commit(`cms/${MUTATIONS.SET_CONTENT_DRAGGING_DISABLED}`, false)
          emitBlur(event)
        }
      },
      class: {
        [`text-h${props.size}`]: !!props.size
      },
      props: {
        item: props.item,
        identifier: props.identifier,
        ...context.data.attrs
      }
    })
  }
})
