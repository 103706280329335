



































import Vue from 'vue'
import { ExerciseOptions } from '../mixins/Exercise'

export default Vue.extend({
  name: 'ExercisePropertyEditor',

  model: {},

  props: {
    value: Object as () => ExerciseOptions
  },

  computed: {
    valueProxy (): ExerciseOptions {
      return this.value || {}
    },
    isExercise: {
      get (): boolean {
        return this.valueProxy.isExercise || false
      },
      set (v: boolean) {
        this.setValue('isExercise', v)
      }
    },
    solution: {
      get (): number | string | void {
        return this.valueProxy.solution
      },
      set (v: string) {
        this.setValue('solution', v)
      }
    },
    points: {
      get (): number | void {
        return this.valueProxy.points
      },
      set (v: string | undefined) {
        this.setValue('points', v ? parseFloat(v) : v)
      }
    }
  },

  methods: {
    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    }
  }
})
