


























import Vue from 'vue'
import CMSEditableTextWrapper from './CMSEditableTextWrapper'
import { ContentTree } from '@simpl/cms/types'
import { DragItem } from '../types'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import onTouchStart from '../utils/handle-clone-element-drag'

export default Vue.extend({
  name: 'CDragItem',

  components: {
    CMSEditableTextWrapper
  },

  props: {
    content: Object as () => ContentTree,
    item: Object as () => DragItem,
    width: [Number, String],
    height: [Number, String]
  },

  data () {
    return {
      show: true
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    element (): HTMLElement {
      return this.$el as HTMLElement
    },
    kickedOutItemIdentifier (): string | null {
      return this.$store.state.cms.kickedOutOfDropZoneItem
    }
  },

  watch: {
    kickedOutItemIdentifier (v) {
      if (v && !this.show) {
        this.show = v === this.item.identifier
      }
    }
  },

  mounted () {
    this.element.addEventListener('touchstart', this.handleTouchStart)
    this.element.addEventListener('dragstart', this.onDragStart)
    this.element.addEventListener('dragend', this.onDragEnd)
  },
  beforeDestroy () {
    this.element.removeEventListener('touchstart', this.handleTouchStart)
    this.element.removeEventListener('dragstart', this.onDragStart)
    this.element.removeEventListener('dragend', this.onDragEnd)
  },

  methods: {
    getTextForTemporaryUserLanguage,

    handleTouchStart (e: TouchEvent) {
      onTouchStart(e)
      this.show = false
      this.element.removeEventListener('touchstart', this.handleTouchStart)
    },
    onDragStart () {
      this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_IDENTIFIER}`, this.item.identifier)
    },
    onDragEnd () {
      this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_IDENTIFIER}`, null)
      this.element.addEventListener('touchstart', this.handleTouchStart)

      if (this.$store.state.cms.draggedItemDropSuccessful) {
        this.show = false
        this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_DROP_SUCCESSFUL}`, false)
      } else {
        this.show = true
      }
    }
  }
}
)
