





































import mixins from 'vue-typed-mixins'
import { ContentTree } from '@simpl/cms/types'
import { AudioComponent } from '../types'
import AssetDragDrop from '../mixins/AssetDragDrop'
import Layout from '../mixins/Layout'
import AudioPlayer from '@simpl/core/components/audioplayer/AudioPlayer.vue'
import AudioPropertyEditor from '../property-editors/AudioPropertyEditor.vue'

export default mixins(AssetDragDrop, Layout).extend({
  name: 'CAudioPlayer',

  components: {
    AudioPlayer
  },

  props: {
    content: Object as () => ContentTree,
    audio: {
      type: Object as () => AudioComponent,
      default: {
        url: undefined,
        autoplay: false
      }
    }
  },

  data () {
    return {
      mediaType: 'audio'
    }
  },

  computed: {
    src (): string | void {
      return this.audio.url
    },
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  watch: {
    src (v) {
      if (!v) return

      this.uploading = false
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="46.953" height="44.293" viewBox="0 0 46.953 44.293" style="height: 50px; width: 50px;">
  <g id="Gruppe_416" data-name="Gruppe 416" transform="translate(-24.524 -19.977)">
    <path id="volume_up_black_24dp" d="M3,19.675v9.99a2.5,2.5,0,0,0,2.5,2.5H12.99l8.216,8.216A2.5,2.5,0,0,0,25.476,38.6v-27.9a2.5,2.5,0,0,0-4.271-1.773L12.99,17.177H5.5A2.5,2.5,0,0,0,3,19.675Z" transform="translate(22.524 17.461)" fill="none" stroke="#707070" stroke-width="2"/>
    <path id="volume_up_black_24dp-2" data-name="volume_up_black_24dp" d="M36.715,24.669A11.239,11.239,0,0,0,30.471,14.6v20.1A11.173,11.173,0,0,0,36.715,24.669ZM30.471,5.814v.5a2.322,2.322,0,0,0,1.5,2.123,17.484,17.484,0,0,1,0,32.466,2.286,2.286,0,0,0-1.5,2.123v.5a2.251,2.251,0,0,0,3.022,2.123,22.447,22.447,0,0,0,0-41.956,2.241,2.241,0,0,0-3.022,2.123Z" transform="translate(22.524 17.461)" fill="none" stroke="#707070" stroke-width="2"/>
  </g>
</svg>`,
    category: {
      key: 'media'
    },
    layout: {
      disabled: ['alignments', 'typography']
    },
    props () {
      return [{
        key: 'settings',
        properties: [{
          key: 'audio',
          type: 'AudioComponent',
          component: AudioPropertyEditor
        }]
      }]
    }
  }
})
