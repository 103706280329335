import Vue from 'vue'
import { DragDropHandler } from '../utils/drag-drop'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { File } from '@simpl/core/types/graphql'
import uploadAsset from '../utils/upload-asset'
import { scheduleUpdateQueue } from '../utils/update-queue'
import { ContentTree } from '@simpl/cms/types'

export default Vue.extend({
  props: {
    content: Object as () => ContentTree
  },

  data () {
    return {
      mediaType: 'image',
      assetDragOverUrl: null! as string,
      uploading: false
    }
  },

  computed: {
    _editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    dropTarget (): HTMLElement {
      return this.$el as HTMLElement
    },
    draggedFilesCount: {
      get (): number {
        return this.$store.state.cms._dragDropFileCount
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_COUNT}`, v)
      }
    },
    draggedAsset (): File {
      return this.$store.state.cms._dragDropAssetData
    },
    urlProp: {
      get (): string {
        return this.mediaType === 'application/json'
          ? this.content.data!.properties.url
          : this.content.data!.properties[this.mediaType].url
      },
      set (v: string) {
        if (this.mediaType === 'application/json') {
          this.content.data!.properties.url = v
        } else {
          this.content.data!.properties[this.mediaType].url = v
        }
      }
    }
  },

  async mounted () {
    if (this._editMode && !(this as any).__dragDropHandler__) {
      window.setTimeout(() => {
        this._initDragDropHandler()
      }, 1000)
    }
  },
  beforeDestroy () {
    const thisAny: any = this
    thisAny.__dragDropHandler__ && this._destroyDragDropHandler()
  },

  methods: {
    async getDataTransfer (e: DragEvent): Promise<string | false> {
      this.draggedFilesCount = 0
      const file = e.dataTransfer!.files[0]
      if (file.type.includes(this.mediaType)) {
        this.uploading = true
        const uploadedFile = await uploadAsset(this, file)
        if (uploadedFile) {
          this.urlProp = (uploadedFile as any).data.uploadAsset.url
          return this.urlProp
        }
      } else {
        this.onInvalidTypeDrop()
      }
      return false
    },
    async onDrop (e: DragEvent) {
      if (this.draggedAsset) {
        if (!this.draggedAsset.mime_type.includes(this.mediaType)) {
          this.onInvalidTypeDrop()
          return
        }

        this.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, true)

        this.urlProp = this.draggedAsset.url!
        this.assetDragOverUrl = null!
      } else {
        await this.getDataTransfer(e)
      }

      scheduleUpdateQueue()
    },
    dragValidator () {
      return !!this.draggedAsset || this.draggedFilesCount === 1
    },
    onDragOver () {
      if (this.draggedAsset) {
        this.assetDragOverUrl = this.draggedAsset.url!
      }
    },
    onDragLeave () {
      this.assetDragOverUrl = null!
    },

    onInvalidTypeDrop () {
      this.$notification.publish('bottom', {
        message: this.$t('cms.message.invalidFileType'),
        type: 'error',
        color: 'error'
      })
    },

    _initDragDropHandler () {
      (this as any).__dragDropHandler__ = new DragDropHandler(
        this.dropTarget,
        this.dragValidator,
        this.onDrop,
        this.$options.name,
        this.onDragOver,
        this.onDragLeave,
        false
      )
    },

    _destroyDragDropHandler () {
      (this as any).__dragDropHandler__.destroy();
      (this as any).__dragDropHandler__ = null
    }
  }
})
