





















































import ui from '@simpl/core/plugins/ui'
import { inject, debounce } from '@simpl/core/utils'
import { ContentTree, TickAmount } from '@simpl/cms/types'
import { Content } from '@simpl/core/types/graphql'
import EvaluationButton from './EvaluationButton.vue'
import { CMSComponentPropertyGroup } from '../index'
import ExercisePropertyEditor from '../property-editors/ExercisePropertyEditor.vue'
import TrackingPropertyEditor from '../property-editors/TrackingPropertyEditor.vue'
import { TrackingOptions } from '../types'
import mixins from 'vue-typed-mixins'
import Trackable from '../mixins/Trackable'
import TrackingVisualizationCreation from '../mixins/TrackingVisualizationCreation'
import WithUserInput from '../mixins/WithUserInput'
import Layout from '../mixins/Layout'
import getTickAmount from '../utils/tick-amount'

const injectMixin = inject({
  rootContent: { default: undefined! as ContentTree }
})

export default mixins(injectMixin, Trackable, TrackingVisualizationCreation, WithUserInput, Layout).extend({
  name: 'CSlider',

  components: { EvaluationButton },

  props: {
    content: Object as () => ContentTree,
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 100
    },
    value: {
      type: [Number, String],
      default: 50
    },
    showSteps: Boolean,
    step: {
      type: [Number, String],
      default: 1
    },
    showLabel: Boolean,
    showIcon: Boolean,
    prependIcon: String,
    disabled: Boolean,
    range: Boolean,
    exercise: Boolean,
    trackingSettings: Object as () => TrackingOptions,
    required: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      internalValue: 0 as string | number,
      correct: null! as boolean,
      evaluated: false,
      evaluationFeedback: null! as number[]
    }
  },

  computed: {
    valueProxy: {
      get (): number | string {
        if (!this.editMode) {
          return this.readValue() || this.internalValue
        }
        return this.content.data!.properties?.value
      },
      set (v: number | string) {
        if (!this.editMode) {
          this.internalValue = v

          if (!this.isExercise) {
            this.updateValue()
          }
        } else {
          this.content.data!.properties.value = parseFloat(v as string)
        }
      }
    },
    editMode (): boolean {
      return this.$store.state.cms?.editMode
    },
    readonly (): boolean {
      const cms = this.$store.state.cms || {}
      return (cms.editMode && cms.selectedComponentId !== this.content.id) || (this.evaluated && this.isExercise)
    },
    isExercise (): boolean {
      return !!this.exerciseSettings?.isExercise
    },
    completed (): boolean {
      return !!this.$store.state.cms.userInput[this.content.id]?.completed
    },
    showEvaluation (): boolean {
      return (this.$store.getters['module/showTestEvaluation'] ||
        (this.evaluated && !this.$store.state.module.isTest)) &&
        !!this.exerciseSettings?.solution
    },
    sliderRange (): number {
      return Math.abs(parseFloat(this.min as any) - parseFloat(this.max as any))
    }
  },

  watch: {
    step (v) {
      if (v) {
        this.content.data!.properties.value =
          Math.floor(parseFloat(this.value as string) / v) * v
        this.content.data!.properties.min =
          Math.floor(parseFloat(this.min as string) / v) * v
        this.content.data!.properties.max =
          Math.floor(parseFloat(this.max as string) / v) * v
      }
    },
    completed: {
      immediate: true,
      handler (v) {
        if (v && this.readValue() && this.exerciseSettings) this.evaluate()
        if (!v && this.readValue()) this.setCompleted()
      }
    }
  },

  mounted () {
    if (this.readValue()) {
      if (this.exerciseSettings) {
        this.evaluate()
      } else {
        this.setCompleted()
      }
    }
  },

  methods: {
    evaluate (): void {
      this.evaluated = true
      if (!this.readValue()) {
        this.writeValue(this.internalValue)
      }

      const solutionAsNumber = parseFloat(this.exerciseSettings.solution as any)
      const valueAsNumber = parseFloat((this.internalValue || this.readValue()) as any)

      this.correct = solutionAsNumber === valueAsNumber
      this.setCompleted()
      this.setCorrect(this.correct)

      if (this.exerciseSettings?.points && this.correct) {
        this.setPoints(this.exerciseSettings.points)
      }
    },
    updateValue: debounce(function (this: any) {
      this.writeValue(this.internalValue)

      if (!this.isExercise) this.setCompleted()
    }, 500)
  },

  cms: {
    preview () {
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 26">
  <defs>
    <style>
      .c-slider--a {
        fill: gray;
      }

      .c-slider--b, .c-slider--c {
        fill: ${ui.framework.theme.currentTheme.primary};
      }

      .c-slider--b {
        opacity: 0.5;
      }
    </style>
  </defs>
  <rect class="c-slider--a" y="10" width="104" height="6" rx="2"/>
  <circle class="c-slider--b" cx="40" cy="13" r="13"/>
  <circle class="c-slider--c" cx="40" cy="13" r="9"/>
</svg>`
    },
    category: {
      key: 'interactive'
    },
    layout: {
      disabled: ['align', 'typography']
    },
    props () {
      const props: CMSComponentPropertyGroup[] = []

      const groupExercise: CMSComponentPropertyGroup = {
        key: 'exercise',
        properties: [{
          key: 'exerciseSettings',
          type: 'ExerciseOptions',
          component: ExercisePropertyEditor,
          range: -1
        }]
      }

      const groupSettings: CMSComponentPropertyGroup = {
        key: 'settings',
        properties: [{
          key: 'min',
          type: 'number',
          step: this.step,
          trackingSensitive: true
        }, {
          key: 'max',
          type: 'number',
          step: this.step,
          trackingSensitive: true
        }, {
          key: 'value',
          type: 'number',
          step: this.step
        }, {
          key: 'showSteps',
          type: 'boolean'
        }]
      }

      if (this.showSteps) {
        groupSettings.properties!.push({
          key: 'step',
          type: 'number'
        })
      }

      if (!this.required) {
        groupSettings.properties!.push({
          key: 'disabled',
          type: 'switch'
        })
      }

      const groupVisual: CMSComponentPropertyGroup = {
        key: 'visual',
        properties: [{
          key: 'showLabel',
          type: 'boolean'
        }, {
          key: 'showIcon',
          type: 'boolean'
        }]
      }

      if (this.showIcon) {
        groupVisual.properties!.push({
          key: 'prependIcon',
          type: 'radio',
          values: ['mdi-emoticon-outline', 'mdi-emoticon-frown-outline']
        })
      }

      const groupTracking: CMSComponentPropertyGroup = {
        key: 'tracking',
        order: 6,
        properties: [{
          key: 'trackingSettings',
          type: 'TrackingOptions',
          component: TrackingPropertyEditor
        }]
      }

      return [groupExercise, groupSettings, groupVisual, groupTracking]
    },
    tracking: {
      parseTrackingForVisualization (tracking: Record<string, any>, content?: Content): any[] {
        const properties = content?.data?.properties ? content.data?.properties : tracking.properties?.properties
        const trackingData = tracking.tracking_data

        const tickAmount = getTickAmount(
          parseFloat(properties.min),
          parseFloat(properties.max),
          parseFloat(properties.step)
        )

        const arr = [] as Record<string, any>[]
        const numOfSteps = tickAmount.numOfSteps
        const factor = tickAmount.factor

        const min = properties.min
        const step = properties.step

        for (let i = 0; i <= numOfSteps; i++) {
          const cat = Math.round(factor * parseFloat(min) + factor * parseFloat(step) * i) / factor
          arr.push({ x: cat, y: 0 })
        }

        trackingData.map((td: Record<string, any>) => {
          let currentValue = arr.find(data => data.x === td.data.value)

          if (currentValue) {
            currentValue = { y: currentValue.y++ }
          }
          return currentValue
        })
        return arr
      },

      parseTrackingForTable (tracking: Record<string, any>, content?: Content, categories?: string[] | number[]): any[] {
        const arr = [] as any[]
        let category = ''
        let participations = 0
        let percentage = 0

        tracking.seriesData.forEach((data: { x: string, y: number }) => {
          participations += data.y
        })
        tracking.seriesData.forEach((data: any, index: number) => {
          percentage = participations && data.y / participations
          category = categories ? String(categories[index]) : data.x
          arr.push([category, data.y, percentage])
        })
        return arr
      }
    },
    visualization: {
      diagramTypes: ['bar', 'line', 'pie', 'donut'],
      hasNumericCategories: true,
      getTextForCategories (content: Content): number[] {
        const properties = content?.data?.properties ? content.data?.properties : null
        const tickAmount = getTickAmount(
          parseFloat(properties.min),
          parseFloat(properties.max),
          parseFloat(properties.step)
        )

        const categories = [] as number[]
        const numOfSteps = tickAmount.numOfSteps
        const factor = tickAmount.factor

        const min = properties.min
        const step = properties.step

        for (let i = 0; i <= numOfSteps; i++) {
          const cat = Math.round(factor * parseFloat(min) + factor * parseFloat(step) * i) / factor
          categories.push(cat)
        }
        return categories
      },

      getTickAmount (content: Content): TickAmount {
        const properties = content.data.properties
        return getTickAmount(
          parseFloat(properties.min),
          parseFloat(properties.max),
          parseFloat(properties.step)
        )
      }
    }
  }
})
