import { TickAmount } from '@simpl/cms/types'

export default function getTickAmount (min:number, max:number, step:number): TickAmount {
  const factor = calculateFactor(step)
  const numOfSteps = calculateNumOfSteps(min, max, step, factor)
  const tickAmount = calculateTickAmount(numOfSteps)
  return {
    default: tickAmount,
    current: tickAmount,
    numOfSteps: numOfSteps,
    factor: factor
  }
}

function calculateFactor (step:number): number {
  let factor = 1 // IMPORTANT - create factor to get rid of floating point precision
  while (factor * step % 1 !== 0) {
    factor *= 10
  }
  return factor
}

function calculateNumOfSteps (min:number, max:number, step:number, factor: number): number {
  const factoredStep = factor * step
  const factoredMin = Math.round(factor * min)
  const factoredMax = Math.round(factor * max)
  const factoredRange = Math.abs(factoredMax - factoredMin)
  return factoredRange / factoredStep
}

function calculateTickAmount (numOfSteps:number): number {
  let divisor = 1
  while (numOfSteps / divisor >= 10) {
    divisor++
  }
  return numOfSteps / divisor
}
