
















import { ContentTree } from '@simpl/cms/types'
import mixins from 'vue-typed-mixins'
import ComponentDragDropDefault from '../mixins/DragDropHandler'
import Layout from '../mixins/Layout'

export default mixins(
  ComponentDragDropDefault,
  Layout
).extend({
  name: 'CContainer',

  inject: {
    editMode: { default: false }
  },

  props: {
    content: Object as () => ContentTree
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 80">
  <defs>
    <style>
      .c-container--a {
        fill: gray;
      }

      .c-container--b {
        fill: #fff;
      }

      .c-container--c {
        fill: #b3b3b3;
      }
    </style>
  </defs>
  <path class="c-container--a" d="M117,3V77H3V3H117m1-3H2A2,2,0,0,0,0,2V78a2,2,0,0,0,2,2H118a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Z"/>
  <rect class="c-container--a" x="1" y="1" width="118" height="7"/>
  <circle class="c-container--b" cx="6" cy="4" r="1.5"/>
  <circle class="c-container--b" cx="11" cy="4" r="1.5"/>
  <circle class="c-container--b" cx="16" cy="4" r="1.5"/>
  <path class="c-container--c" d="M86,13H80v2h6V13ZM74,13H68v2h6V13ZM62,13H56v2h6V13ZM50,13H44v2h6V13ZM38,13H32v2h6V13ZM26,13H22v2h4V13Zm-2,8H22v6h2V21Zm0,12H22v6h2V33Zm0,12H22v6h2V45Zm0,12H22v6h2V57Zm0,12H22v3h3V70H24V69Zm13,1H31v2h6V70Zm12,0H43v2h6V70Zm12,0H55v2h6V70Zm12,0H67v2h6V70Zm12,0H79v2h6V70Zm12,0H91v2h6V70Zm1-9H96v6h2V61Zm0-12H96v6h2V49Zm0-12H96v6h2V37Zm0-12H96v6h2V25Zm0-12H92v2h4v4h2V13Z"/>
</svg>`,
    category: {
      key: 'layout',
      order: 2
    }
  }
})
