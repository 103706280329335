









































import Vue from 'vue'
import ComponentLibrary from '../sidebar/ComponentLibrary.vue'

export default Vue.extend({
  name: 'ComponentLibraryDialog',

  components: { ComponentLibrary },

  model: {},

  props: {
    value: Boolean
  },

  data () {
    return {
      selected: null! as string
    }
  },

  computed: {
    show: {
      get () : boolean {
        return this.value
      },
      set (v: boolean): void {
        this.$emit('input', v)
      }
    }
  },

  watch: {
    show (v) {
      if (!v) this.selected = null!
    }
  },

  methods: {
    selectComponent () {
      this.$emit('select-component', this.selected)
      this.hide()
    },
    hide () {
      const libComp = this.$refs.library as any
      libComp.reset()
      this.show = false
    }
  }
})
