<template>
  <div
    class="video-player fill-height"
    @mousemove="resetMouseTimer"
  >
    <video
      ref="video"
      playsinline
      controlslist="nodownload nopip"
      disablepictureinpicture
      :loop="hideControls"
      @play="onPlay"
      @pause="onPause"
      @ended="stop(true)"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="onCanPlay"
      @volumechange="onVolumeChange"
    >
      <source
        :src="src"
        type="video/mp4"
      >
    </video>

    <v-slide-y-reverse-transition>
      <VideoPlayerToolbar
        v-if="(!playing || !hideToolbar) && !hideControls"
        :playing.sync="playing"
        :paused.sync="paused"
        :position="position"
        :volume.sync="volume"
        :duration="duration"
        :disabled="!canPlay"
        :fullscreen="fullscreen"
        @stop="stop"
        @seeking="seeking"
        @seeked="seeked"
        @toggle-fullscreen="toggleFullscreen"
      />
    </v-slide-y-reverse-transition>
    <v-fade-transition>
      <v-btn
        v-if="(!playing && canPlay)"
        class="big-play"
        @click="playing = true"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
  import VideoPlayerToolbar from './VideoPlayerToolbar'
  import Screenfull from 'screenfull'

  export default {
    name: 'VideoPlayer',

    components: { VideoPlayerToolbar },

    props: {
      src: String,
      startAt: {
        type: [String, Number],
        default: 0
      },
      userVolume: {
        type: [String, Number],
        default: 1
      },
      autoplay: Boolean,
      hideControls: Boolean
    },

    data () {
      return {
        playing: false,
        paused: false,
        position: 0,
        duration: 0,
        volume: this.userVolume,
        fullscreen: false,
        beforeSeek: null,
        canPlay: false,
        mouseMoveTimeout: null,
        hideToolbar: false
      }
    },

    computed: {
      video () {
        return this.$refs.video
      }
    },

    watch: {
      playing (v) {
        if (v && this.position === this.video.duration) {
          this.resetPosition()
        }
        v ? this.video.play() : this.video.pause()
      },
      paused (v) {
        v ? this.video.pause() : this.video.play()
      },
      volume (v) {
        this.video.volume = v
        this.$emit('volumechange', v)
      },
      position (v) {
        if (this.duration) {
          this.$emit('progress', v / this.duration)
        }
      }
    },

    mounted () {
      if (this.autoplay) {
        this.playing = true
      }
    },
    beforeDestroy () {
      this.video.pause()
      this.video.removeAttribute('src')
      this.video.load()
    },

    methods: {
      stop (keepPosition) {
        this.$refs.video.pause()
        if (!keepPosition) {
          this.resetPosition()
        }
      },
      resetPosition () {
        this.$refs.video.currentTime = 0
        this.position = 0
      },
      seeking (v) {
        if (!this.beforeSeek) {
          this.beforeSeek = {
            playing: this.playing,
            paused: this.paused
          }
          this.paused = true
          this.playing = false
        }

        this.video.currentTime = v
      },
      seeked (v) {
        this.seeking(v)

        this.playing = this.beforeSeek.playing
        this.paused = this.beforeSeek.paused
        this.beforeSeek = null
      },
      async toggleFullscreen () {
        await Screenfull.toggle(this.$el)
        this.fullscreen = Screenfull.isFullscreen
      },

      onCanPlay () {
        this.duration = this.video.duration
        this.position = parseFloat(this.startAt) * this.video.duration
        this.video.currentTime = this.position
        this.canPlay = true
      },
      onPlay () {
        this.playing = true
        this.paused = false
      },
      onPause () {
        this.playing = false
        this.paused = true
      },
      onTimeUpdate () {
        /** @type {HTMLVideoElement} */
        const video = this.$refs.video

        // Video element keeps sending timeupdate a few times
        // even if view is destroyed
        if (!video) {
          return
        }
        if (video.duration) {
          this.position = video.currentTime
        } else {
          this.position = -1
        }
      },
      onVolumeChange () {
        this.volume = this.video.volume
      },

      resetMouseTimer () {
        clearTimeout(this.mouseMoveTimeout)
        this.hideToolbar = false
        this.mouseMoveTimeout = setTimeout(() => {
          this.hideToolbar = true
        }, 2000)
      }
    }
  }

</script>

<style lang="scss">
  .video-player {
    position: relative;

    video {
      /* Make video to at least 100% wide and tall */
      max-width: 100%;
      max-height: 100%;

      /* Setting width & height to auto prevents the browser from stretching or squishing the video */
      width: 100%;
      height: 100%;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .big-play {
      $icon-size: 100px;
      position: absolute;
      height: 200px !important;
      width: 200px !important;
      max-width: 35vw;
      max-height: 35vw;
      border-radius: 50% !important;
      opacity: 0.8;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      i {
        scale: 3;
      }
    }
  }
</style>
