import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'

export default Vue.extend({
  name: 'Trackable',

  inject: {
    adapter: { default: undefined }
  },

  props: {
    content: Object as () => ContentTree
  },

  methods: {
    readValue (defaultValue?: any): any {
      return (this as any).adapter?.read(
        this.content.id,
        { value: defaultValue ?? '' }
      ).value
    },
    writeValue (value: any, score?: number) {
      if (typeof score !== 'undefined') {
        (this as any).adapter?.write(
          this.content.id,
          value,
          score
        )
      } else {
        (this as any).adapter?.write(
          this.content.id,
          value
        )
      }
    }
  }
})
