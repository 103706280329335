



















import { ContentTree } from '@simpl/cms/types'
import mixins from 'vue-typed-mixins'
import ComponentDragDropDefault from '../mixins/DragDropHandler'
import Layout from '../mixins/Layout'

export default mixins(ComponentDragDropDefault, Layout).extend({
  name: 'CSection',

  inject: {
    editMode: { default: false },
    adapter: { default: undefined }
  },

  props: {
    content: Object as () => ContentTree,
    columns: Number,
    screenPreview: Boolean
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 80">
  <defs>
    <style>
      .c-section--a {
        fill: gray;
      }

      .c-section--b {
        fill: #fff;
      }

      .c-section--c {
        fill: #b3b3b3;
      }
    </style>
  </defs>
  <path class="c-section--a" d="M117,3V77H3V3H117m1-3H2A2,2,0,0,0,0,2V78a2,2,0,0,0,2,2H118a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Z"/>
  <rect class="c-section--a" x="1" y="1" width="118" height="7"/>
  <circle class="c-section--b" cx="6" cy="4" r="1.5"/>
  <circle class="c-section--b" cx="11" cy="4" r="1.5"/>
  <circle class="c-section--b" cx="16" cy="4" r="1.5"/>
  <path class="c-section--c" d="M100,11H94v2h6V11ZM88,11H82v2h6V11ZM76,11H70v2h6V11ZM64,11H58v2h6V11ZM52,11H46v2h6V11ZM40,11H34v2h6V11ZM28,11H22v2h6V11ZM16,11H10v2h6V11Zm-6,4H8v6h2V15Zm0,12H8v6h2V27Zm2.42,11.58H8V39H8v0H8v1.58h4.42v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2Zm12,0h-6v2h6v-2ZM112,32.15h-2v6h2v-6Zm0-12h-2v6h2v-6ZM112,11h-6v2h4v1.15h2V11Z"/>
  <path class="c-section--c" d="M100,43.42H94v2h6v-2Zm-12,0H82v2h6v-2Zm-12,0H70v2h6v-2Zm-12,0H58v2h6v-2Zm-12,0H46v2h6v-2Zm-12,0H34v2h6v-2Zm-12,0H22v2h6v-2Zm-12,0H10v2h6v-2Zm-6,4H8v6h2v-6Zm0,12H8v6h2v-6ZM12.42,71H8v.42H8v0H8V73h4.42V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71Zm12,0h-6v2h6V71ZM112,64.58h-2v6h2v-6Zm0-12h-2v6h2v-6Zm0-9.16h-6v2h4v1.16h2V43.42Z"/>
</svg>`,
    category: {
      key: 'layout'
    },
    dragDrop: {
      deniedParents: ['CSection']
    }
  }
})
