
































import { ContentTree } from '@simpl/cms/types'
import mixins from 'vue-typed-mixins'
import Layout from '../mixins/Layout'
import { CMSComponentPropertyGroup } from '../index'

export default mixins(Layout).extend({
  name: 'CYoutubeVideo',

  props: {
    content: Object as () => ContentTree,
    url: String,
    hideControls: Boolean
  },

  computed: {
    src: {
      get (): string | undefined {
        if (!this.url) return

        const videoId = this.getVideoId(this.url)

        const hideControls = this.hideControls ? '?controls=0' : ''

        return `//www.youtube.com/embed/${videoId}${hideControls}`
      },
      set (v: string) {
        this.content.data!.properties.url = v
      }
    }
  },

  methods: {
    getVideoId (url: string) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return (match && match[2].length === 11)
        ? match[2]
        : null
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40">
  <defs>
    <style>
    .c-youtube-video--a,
    .c-youtube-video--b,
    .c-youtube-video--d{fill:none;}
    .c-youtube-video--a,
    .c-youtube-video--b{stroke:#6d7579;stroke-width:2px;}
    .c-youtube-video--b{stroke-linecap:round;stroke-linejoin:round;}
    .c-youtube-video--c{stroke:none;}
    </style></defs>
      <g transform="translate(-20 -24)"><g transform="translate(4 8)"><g class="c-youtube-video--a" transform="translate(16 16)"><rect class="c-youtube-video--c" width="56" height="40" rx="14"/><rect class="c-youtube-video--d" x="1" y="1" width="54" height="38" rx="13"/></g></g><path class="c-youtube-video--b" d="M122.829,66.181l-12,6.791.237-14Z" transform="translate(-66.829 -21.972)"/></g>
    </svg>`,
    category: {
      key: 'media'
    },
    layout: {
      disabled: ['alignments', 'typography']
    },
    props (): CMSComponentPropertyGroup[] {
      return [{
        key: 'settings',
        properties: [{
          key: 'url',
          type: 'text-field'
        }, {
          key: 'hideControls',
          type: 'boolean'
        }]
      }]
    }
  }
})
