<template>
  <v-toolbar
    class="video-player__toolbar pa-0"
    bottom
    absolute
    width="100%"
    style="top: auto;"
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          height="100%"
          :disabled="disabled"
          @click="playPause"
          v-on="on"
        >
          <v-icon>{{ !playing || paused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t( playing ? 'videoPlayer.action.pause' : 'videoPlayer.action.play') }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          height="100%"
          :disabled="position === 0 || position === duration || disabled"
          @click="$emit('stop')"
          v-on="on"
        >
          <v-icon>mdi-stop</v-icon>
        </v-btn>
      </template>
      <span>{{ $t( 'videoPlayer.action.stop') }}</span>
    </v-tooltip>
    <span
      v-if="!$vuetify.breakpoint.xsOnly && !previewBreakpointXs"
      class="d-flex fill-height flex-grow-0 flex-shrink-0 align-center pr-6"
      @mouseenter="showVolume = true"
      @mouseleave="showVolume = false"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            text
            height="100%"
            @click="$emit('update:volume', volume > 0 ? 0 : 0.5)"
            v-on="on"
          >
            <v-icon>{{ volumeIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( volume > 0 ? 'videoPlayer.action.mute' : 'videoPlayer.action.unmute') }}</span>
      </v-tooltip>
      <v-expand-x-transition>
        <v-slider
          v-if="showVolume"
          v-model="internalVolume"
          class="flex-shrink-0 flex-grow-0"
          style="width: 100px;"
          step="0.01"
          min="0"
          max="1"
          hide-details
        />
      </v-expand-x-transition>
    </span>

    <v-slider
      :class="{ 'ml-6': $vuetify.breakpoint.sm }"
      :value="internalPosition"
      :step="0"
      :max="duration"
      :disabled="disabled"
      hide-details
      @mousedown="isSeeking = true"
      @input="onSeeking"
      @change="onSeeked"
    />

    <span class="mx-3">
      {{ position | sec2time(duration) }} <span v-if="!$vuetify.breakpoint.xsOnly && !previewBreakpointXs">
        / {{ duration | sec2time(duration) }}</span>
    </span>
    <v-tooltip
      v-if="fullscreenEnabled"
      top
    >
      <template #activator="{ on }">
        <v-btn
          text
          height="100%"
          @click="toggleFullscreen"
          v-on="on"
        >
          <v-icon>{{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t( 'videoPlayer.action.fullscreenOn') }}</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
  import screenfull from 'screenfull'

  export default {
    name: 'VideoPlayerToolbar',

    filters: {
      sec2time (value, duration) {
        const pad = function (num) { return ('000' + num).slice(-2) }
        const hasHours = Math.floor(duration / 60 / 60) > 0
        const time = parseFloat(value).toFixed(3)
        const hours = Math.floor(time / 60 / 60)
        const minutes = Math.floor(time / 60) % 60
        const seconds = Math.floor(time - minutes * 60)

        return `${hasHours ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(seconds)}`
      }
    },

    props: {
      playing: Boolean,
      paused: Boolean,
      position: Number,
      muted: Boolean,
      duration: Number,
      disabled: Boolean,
      volume: Number,
      fullscreen: Boolean
    },

    data: () => ({
      isSeeking: false,
      showVolume: false,
      fullscreenEnabled: screenfull.isEnabled
    }),

    computed: {
      internalPosition: {
        get () { return this.position }
      },
      internalVolume: {
        get () { return this.volume },
        set (v) {
          this.$emit('update:volume', v)
        }
      },
      volumeIcon () {
        if (this.volume === 0) return 'mdi-volume-off'
        if (this.volume > 0 && this.volume < 0.3) return 'mdi-volume-low'
        if (this.volume >= 0.3 && this.volume < 0.7) return 'mdi-volume-medium'
        return 'mdi-volume-high'
      },
      previewBreakpointXs () {
        if (!document.getElementsByTagName('iframe').length) {
          return false
        }
        const iframeWidth = document.getElementsByTagName('iframe')[0].style.width
        return parseInt(iframeWidth) < 400 || false
      }
    },

    methods: {
      playPause () {
        if (!this.playing || this.paused) {
          this.$emit('update:playing', true)
          this.$emit('update:paused', false)
        } else {
          this.$emit('update:playing', false)
          this.$emit('update:paused', true)
        }
      },

      onSeeking (v) {
        if (!this.isSeeking) return

        this.$emit('seeking', v)
      },
      onSeeked (v) {
        this.$emit('seeked', v)

        this.isSeeking = false
      },

      toggleFullscreen () {
        this.$emit('toggle-fullscreen')
      }
    }
  }
</script>

<style lang="scss">
  .video-player__toolbar {
    opacity: 0.8;
    transition: $primary-transition;
    transition-property: opacity;

    &:hover {
      opacity: 1.0;
    }

    .v-btn,
    .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: transparent !important;
      box-shadow: none;
    }
  }
</style>
