












































import Vue from 'vue'
import { VideoComponent } from '../types'

export default Vue.extend({
  name: 'VideoPropertyEditor',

  model: {},

  props: {
    value: Object as () => VideoComponent
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || ''
    }
  },

  computed: {
    valueProxy (): VideoComponent {
      return this.value || {}
    },
    url: {
      get (): string | undefined | null {
        return this.valueProxy.url
      },
      set (v: string | null) {
        this.setValue('url', v)
      }
    },
    thumbnailUrl (): string {
      return this.apiUrl && this.valueProxy.slug ? `${this.apiUrl}/thumbnail/${this.valueProxy.slug}` : ''
    },
    autoplay: {
      get (): boolean | void {
        return this.valueProxy.autoplay
      },
      set (v: boolean) {
        this.setValue('autoplay', v)
      }
    },
    hideControls: {
      get (): boolean | void {
        return this.valueProxy.hideControls
      },
      set (v: boolean) {
        this.setValue('hideControls', v)
      }
    }
  },

  watch: {
    hideControls (v) {
      if (!v) return

      this.setValue('autoplay', true)
    }
  },

  methods: {
    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    },
    removeVideo () {
      this.url = null
    }
  }
})
