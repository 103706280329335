import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { hasOwn } from '@simpl/core/utils'
import type { VNode } from 'vue/types'
import { scheduleUpdateQueue } from '../utils/update-queue'
import { isDev } from '@simpl/core/utils/env'
import store from '@simpl/core/plugins/store'
import i18n from '@simpl/core/plugins/i18n'

// let tabindex = 0
let ghostElement: HTMLElement = null!

const listeners = {
  mouseup: (e: MouseEvent) => {
    const instance = (e.currentTarget as any).__vue__

    if (!instance) return

    instance.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, instance.content.id)

    e.stopImmediatePropagation()
  },
  mouseover: (e: MouseEvent) => {
    e.stopPropagation()
    const el = e.currentTarget as HTMLElement
    el.classList.add('hover')
  },
  mouseout: (e: MouseEvent) => {
    const el = e.currentTarget as HTMLElement
    el.classList.remove('hover')
  }
}

const dragListeners = {
  dragstart: (e: DragEvent) => {
    const instance = e.currentTarget!.__vue__

    if (!instance) return

    instance.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_COMPONENT_DATA}`, {
      tag: instance.$options.name!,
      move: true,
      id: (instance as any).content.id
    })

    createGhostElement(instance)
    e.dataTransfer!.setDragImage(ghostElement, 0, 0)

    e.stopPropagation()
  },
  dragend () {
    ghostElement?.parentElement?.removeChild(ghostElement)
  }
}

export default Vue.extend({
  name: 'CMSEditWrapper',

  functional: true,

  props: {
    content: Object as () => ContentTree
  },

  render: (h, context) => {
    const { props } = context
    const { content } = props
    const { attrs } = context.data

    let latestContentId = content.id

    if (!content.data!.properties || Array.isArray(content.data!.properties)) {
      Vue.set(content.data!, 'properties', {})
    }

    const componentDef = Vue.component(content.data!.tag) as any
    const componentProps = content.data!.properties!

    if (componentDef) {
      let contentFound = false
      const defProps = componentDef.options.props
      for (const key in defProps) {
        if (key === 'content') {
          contentFound = true
          continue
        }
        if (!hasOwn(componentProps, key) && !hasOwn(attrs, key)) {
          Vue.set(componentProps, key, getPropDefaultValue(context, defProps[key]))
        }
      }

      if (isDev && !contentFound) {
        console.warn(`No property "content" found in CMS component ${content.data!.tag}`)
      }
    }

    const selectedContentId = store.state.cms.selectedComponentId
    const selected = selectedContentId
      ? selectedContentId === content.id
      : false

    const draggable = componentDef.options.cms?.draggable !== false && !store.state.cms._contentDraggingDisabled

    const containerClass: string[] = (componentDef.options.cms?.containerClass || [])
    const containerClassObject = containerClass.reduce<Record<string, boolean>>((acc, value) => {
      acc[value] = true
      return acc
    }, {})

    const hoveredTreeComponentId = store.state.cms._hoveredTreeComponentId
    const hovered = hoveredTreeComponentId ? hoveredTreeComponentId === content.id : false

    // tabindex += 1

    const nameHelperEl = document.createElement('span')
    nameHelperEl.innerHTML = i18n.t(`cms.names.${content.data!.tag}`) as string

    return h(content.data!.tag, {
      key: `cms-edit--id-${content.id}`,
      nativeOn: {
        ...listeners,
        ...(draggable ? dragListeners : {})
      },
      class: {
        'cms-edit--selected': selected,
        highlight: hovered,
        [`category--${componentDef.options.cms?.category?.key || 'none'}`]: true,
        ...containerClassObject
      },
      staticClass: 'cms-edit--wrapper',
      attrs: {
        // tabindex,
        'data-component': nameHelperEl.innerText,
        draggable: draggable ? 'true' : undefined
      },
      hook: {
        insert: (vnode: VNode) => {
          const instance: Vue & Record<string, any> = vnode.componentInstance!

          if (!instance) return

          (vnode.elm as any).__isCMSComp__ = true

          instance.$store.commit(`cms/${MUTATIONS.SET_COMPONENT_MAPPING_VALUE}`, {
            instance,
            id: content.id
          })

          instance.__removeCMSChildrenWatcher = instance!.$watch(function (this: any) {
            return { ...this.content.children }
          }, function (this: any) {
            if (this.$store.state.cms._isDirty) {
              this.content._changedByUser = true
            }

            scheduleUpdateQueue()
          }, { deep: true })

          instance.__removeCMSDataPropertiesWatcher = instance!.$watch(function (this: any) {
            return { ...this.content.data!.properties }
          }, function (this: any, n, o) {
            if (deepEqual(n, o)) return

            this.content.data!._changedByUser = true

            scheduleUpdateQueue()
          })

          instance.__removeCMSTextWatcher = instance!.$watch(function (this: any) {
            return { ...this.content.texts }
          }, function (this: any) {
            this.content._textChangedByUser = true

            scheduleUpdateQueue()
          }, { deep: true })
        },
        update: (vnode: VNode) => {
          const instance = vnode.componentInstance as any

          if (!instance) return

          if (latestContentId !== content.id) {
            instance.$store.commit(`cms/${MUTATIONS.SET_COMPONENT_MAPPING_VALUE}`, {
              id: latestContentId
            })
            instance.$store.commit(`cms/${MUTATIONS.SET_COMPONENT_MAPPING_VALUE}`, {
              instance,
              id: content.id
            })
            latestContentId = content.id
          }
        },
        destroy: (vnode: VNode) => {
          const instance = vnode.componentInstance as any

          if (!instance) return

          if (
            latestContentId === instance.content.id &&
            instance.$store.state.cms?.selectedComponentId === instance.content.id
          ) {
            instance.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, null)
          }

          instance.__removeCMSChildrenWatcher()
          instance.__removeCMSDataPropertiesWatcher()
          instance.__removeCMSTextWatcher()
        }
      },
      props: {
        content,
        ...context.data.attrs,
        ...componentProps
      }
    })
  }
})

function createGhostElement (instance: Vue) {
  const doc = (instance.$el as HTMLElement).ownerDocument
  const name = instance.$options.name!
  const preview = instance.$options.cms?.preview
  const previewResult = typeof preview === 'function'
    ? preview.call(instance)
    : preview

  ghostElement = document.createElement('div')
  ghostElement.classList.add('drag-drop--ghost')
  ghostElement.classList.add('v-tooltip__content')
  doc.querySelector('.v-application')!.appendChild(ghostElement)
  ghostElement.innerHTML = `${previewResult || ''}<span>${i18n.t(`cms.names.${name}`)}</span>`
}

function getPropDefaultValue (context: any, prop: any): any {
  // no default, return undefined
  if (!hasOwn(prop, 'default')) {
    return undefined
  }
  const def = prop.default

  // call factory function for non-Function types
  // a value is Function if its prototype is function even across different execution context
  return typeof def === 'function'
    ? def.call(context)
    : def
}

function deepEqual (obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false
      }
    }
    return true
  }

  // Private
  function isObject (obj: any) {
    return typeof obj === 'object' && obj != null
  }
}
