































  import { getTextForUserLanguage } from '@simpl/core/utils'
  import Layout from '../mixins/Layout'
  import Trackable from '../mixins/Trackable'
  import TrackingVisualizationCreation from '../mixins/TrackingVisualizationCreation'
  import { ContentTree } from '@simpl/cms/types'
  import { CMSComponentPropertyGroup } from '../index'
  import ExercisePropertyEditor from '../property-editors/ExercisePropertyEditor.vue'
  import TextInputPropertyEditor from '../property-editors/TextInputPropertyEditor.vue'
  import { TextInputTextKeys } from '../types'
  import mixins from 'vue-typed-mixins'
  import { TranslateResult } from 'vue-i18n'
  import WithUserInput from '../mixins/WithUserInput'
  import TrackingPropertyEditor from '../property-editors/TrackingPropertyEditor.vue'
  import debounce from 'lodash.debounce'
  import EvaluationButton from './EvaluationButton.vue'

  export default mixins(Layout, Trackable, TrackingVisualizationCreation, WithUserInput).extend({
    name: 'CTextInput',

    components: { EvaluationButton },

    props: {
      content: Object as () => ContentTree,
      value: String,
      exercise: Boolean,
      type: {
        type: String,
        default: null
      },
      textKeys: {
        type: Array,
        default: ['placeholder', 'label']
      },
      charLimit: {
        type: [Number, String],
        default: 150
      },
      rowCount: {
        type: [Number, String],
        default: 1
      },
      required: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        correct: null! as boolean,
        evaluated: false,
        text: ''
      }
    },

    computed: {
      placeholder (): string | void {
        return getTextForUserLanguage(this.content, 'placeholder', false)
      },
      label (): string | void {
        return getTextForUserLanguage(this.content, 'label', false)
      },
      rows (): string {
        return this.content.data!.properties.rowCount || 1
      },
      editMode (): boolean {
        return this.$store.state.cms?.editMode
      },
      isExercise (): boolean {
        return !!this.exerciseSettings?.isExercise
      },
      completed (): boolean {
        return !!this.$store.state.cms.userInput[this.content.id]?.completed
      },
      showEvaluation (): boolean {
        return (this.$store.getters['module/showTestEvaluation'] ||
          (this.evaluated && !this.$store.state.module.isTest)) &&
          !!this.exerciseSettings?.solution
      }
    },

    watch: {
      text (v) {
        this.save(v)
      }
    },

    mounted () {
      this.text = this.readValue(this.value)

      this.content.data!.properties.exerciseSettings = {
        ...this.exerciseSettings,
        solutionType: 'text'
      }

      if (this.$store.state.cms.isTest) {
        this.content.data!.properties.exerciseSettings = {
          ...this.exerciseSettings,
          isExercise: true
        }
      }

      if (this.readValue()) {
        if (this.isExercise) {
          this.evaluate()
        } else {
          this.setCompleted()
        }
      }
    },

    methods: {
      checkMaxLength (input: string): boolean | TranslateResult {
        if (!input) return true

        return input?.length <= this.charLimit || this.$t('cms.hint.maxChars', { max: this.charLimit })
      },
      save: debounce(function (this: any, value: string) {
        if (!this.editMode) {
          if (value?.length > 150) {
            this.setCompleted(false)
            return
          }

          this.content.data!.properties.value = value
          this.writeValue(value)

          if (!this.isExercise) {
            this.setCompleted(!!value)
          }
        }
      }, 800),
      evaluate () {
        this.correct = this.text === this.exerciseSettings.solution
        this.evaluated = true
        this.setCorrect(this.correct)
        this.setCompleted()

        if (this.exerciseSettings?.points && this.correct) {
          this.setPoints(this.exerciseSettings.points)
        }
      }
    },

    cms: {
      preview: `<svg xmlns="http://www.w3.org/2000/svg" width="69.5" height="36" viewBox="0 0 69.5 36">
<defs><style>.c-text-input--a,.c-text-input--b,.c-text-input--c{fill:none;stroke:#6d7579;stroke-width:2px;}.c-text-input--b,.c-text-input--c{stroke-linecap:round;}.c-text-input--c{stroke-linejoin:round;}.c-text-input--d{fill:#6d7579;font-size:22px;font-family:Roboto-Regular, Roboto;}</style></defs><g transform="translate(-666 -82)"><g transform="translate(41 -0.5)"><line class="c-text-input--a" y2="34" transform="translate(680 83.5)"/><line class="c-text-input--b" x2="9" transform="translate(675.5 83.5)"/><line class="c-text-input--b" x2="9" transform="translate(675.5 117.5)"/></g><path class="c-text-input--c" d="M715.5,1165H666v20h49.5" transform="translate(1 -1075)"/><path class="c-text-input--c" d="M675,1165h-9v20h9" transform="translate(1400.5 1275) rotate(180)"/><text class="c-text-input--d" transform="translate(671 85)"><tspan x="0" y="21">a</tspan></text></g>
</svg>`,
      category: {
        key: 'interactive'
      },
      layout: {
        disabled: ['justify', 'typography']
      },
      props () {
        const groupExercise: CMSComponentPropertyGroup = {
          key: 'exercise',
          properties: [{
            key: 'exerciseSettings',
            type: 'ExerciseOptions',
            component: ExercisePropertyEditor,
            value: -1
          }]
        }

        const groupSettings: CMSComponentPropertyGroup = {
          key: 'settings',
          properties: [{
            key: 'textKeys',
            type: 'TextInputTextKeys',
            component: TextInputPropertyEditor,
            value: this.content
          }]
        }

        const groupVisual: CMSComponentPropertyGroup = {
          key: 'visual',
          properties: [{
            key: 'rowCount',
            type: 'number'
          }, {
            key: 'type',
            type: 'select',
            values: ['solo', 'filled', 'outlined']
          }]
        }

        const groupTracking: CMSComponentPropertyGroup = {
          key: 'tracking',
          properties: [{
            key: 'trackingSettings',
            type: 'TrackingOptions',
            component: TrackingPropertyEditor
          }]
        }

        return [groupExercise, groupSettings, groupVisual, groupTracking]
      },
      tracking: {
        parseTrackingForVisualization (tracking: Record<string, any>): any[] {
          const trackingData = tracking.tracking_data
          const words = [] as { name: string, value: number }[]
          trackingData.forEach((td: Record<string, any>) => {
            const currentWord = td.data.value
            const index = words.findIndex(word => word.name === currentWord)
            if (index >= 0) {
              words[index].value++
            } else {
              words.push({ name: currentWord, value: 1 })
            }
          })
          return words
        },
        parseTrackingForTable (tracking: Record<string, any>): any[] {
          const arr = [] as any[]
          let participations = 0
          let percentage = 0

          tracking.seriesData.forEach((data: { name: string, value:number }) => {
            participations += data.value
          })
          tracking.seriesData.forEach((data: any, index: number) => {
            percentage = participations && data.value / participations
            arr.push([data.name, data.value, percentage])
          })
          return arr
        }
      },
      visualization: {
        distributable: false,
        diagramTypes: ['wordcloud']
      }
    }
  })
