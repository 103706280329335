






















import Vue from 'vue'
import { TrackingOptions } from '../types'
import { getTextForTemporaryUserLanguage, nameToIdentifier } from '@simpl/core/utils'
import {
  CREATE_TRACKING_VISUALIZATION,
  DELETE_TRACKING_VISUALIZATION_ITEM
} from '@simpl/tracking-evaluation/graphql'
import { ContentTree } from '@simpl/cms/types'
import initializeHeadlines from '../utils/initialize-headlines'

export default Vue.extend({
  name: 'TrackingPropertyEditor',

  model: {},

  props: {
    value: Object as () => TrackingOptions
  },

  data: function () {
    return {
      internalHeadlines: [] as Record<string, any>[]
    }
  },

  computed: {
    valueProxy (): TrackingOptions {
      return this.value || {}
    },
    isTracking: {
      get (): boolean {
        return this.valueProxy.trackingVisualizationItemId ? this.valueProxy.trackingVisualizationItemId?.length > 0 : false
      },
      set (v: boolean) {
        v ? this.createTrackingVisualization() : this.deleteTrackingVisualizationItem()
      }
    },
    headlineId: {
      get (): number {
        return this.valueProxy.headlineId || -1
      },
      set (v: number) {
        this.setValue('headlineId', v)
      }
    },
    headlines: {
      get (): Record<string, any>[] {
        return this.internalHeadlines
      },
      set (v: Record<string, any>[]) {
        this.internalHeadlines = v
      }
    },
    componentId (): number {
      return this.$store.getters['cms/selectedComponent'].content?.id
    },
    selectedContent (): ContentTree {
      return this.$store.getters['cms/selectedContent']
    },
    selectedComponent (): Vue & { content: ContentTree } {
      return this.$store.getters['cms/selectedComponent']
    }
  },

  mounted () {
    const initializedHeadlines = initializeHeadlines(
      this.selectedContent,
      this.selectedComponent.content.id,
      this.headlineId
    )

    this.headlines = initializedHeadlines.headlines
    this.headlineId = initializedHeadlines.headlineId
  },

  methods: {
    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    },
    removeTrackingVisualizationItemId () {
      const {
        trackingVisualizationItemId, ...rest
      } = this.value

      this.$emit('input', rest)
    },

    getText (item: Record<string, any>) {
      const text = getTextForTemporaryUserLanguage(item, 'text')
      if (text) {
        return this.htmlDecode(text)
      }
      return ''
    },

    htmlDecode (input:string) {
      const doc = new DOMParser().parseFromString(input, 'text/html')
      return doc.documentElement.textContent
    },

    getTrackingVisualizationMutationData (): { data: Record<string, any> } {
      const tag = this.$store.getters['cms/selectedComponent'].$options._componentTag
      const visualizationType = tag === 'CTextInput' ? 'wordcloud' : 'graph'

      const identifier = `${tag} ${this.componentId}`
      const data = {
        identifier: nameToIdentifier(identifier),
        type: visualizationType,
        items: {
          create: [{
            module: {
              connect: this.$store.state.cms.moduleId
            },
            objective: String(this.$store.state.cms.selectedSiteId),
            site: String(this.$store.state.cms.selectedSiteId),
            key: String(this.$store.state.cms.selectedComponentId),
            component_tag: String(tag)
          }]
        }
      }
      return { data }
    },

    async createTrackingVisualization () {
      const variables = this.getTrackingVisualizationMutationData()
      const answer = await this.$apollo.mutate({
        mutation: CREATE_TRACKING_VISUALIZATION,
        variables: variables
      })

      this.$notification.publish('bottom', {
        message: this.$t('cms.tracking.successfullyActivated'),
        type: 'success',
        color: 'success'
      })
      const items = answer.data?.createTrackingVisualization?.items

      if (items.length > 0) {
        const firstItem = items[0]
        const firstItemId = firstItem.id
        this.setValue('trackingVisualizationItemId', firstItemId)
      }
    },

    async deleteTrackingVisualizationItem () {
      const answer = await this.$apollo.mutate({
        mutation: DELETE_TRACKING_VISUALIZATION_ITEM,
        variables: {
          id: this.valueProxy.trackingVisualizationItemId
        }
      })
      this.$notification.publish('bottom', {
        message: this.$t('cms.tracking.successfullyDeactivated'),
        type: 'success',
        color: 'success'
      })
      this.$emit('saved')

      const id = answer.data?.createTrackingVisualization?.id
      this.removeTrackingVisualizationItemId()
    }
  }
})
