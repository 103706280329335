














































import { MUTATIONS } from '@simpl/cms/store/consts'
import { ComponentImage } from '../types'
import { ContentTree } from '@simpl/cms/types'
import { File } from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'
import Layout from '../mixins/Layout'
import AssetDragDrop from '../mixins/AssetDragDrop'
import { scheduleUpdateQueue } from '../utils/update-queue'

export default mixins(AssetDragDrop, Layout).extend({
  name: 'CImage',

  props: {
    content: Object as () => ContentTree,
    screenPreview: Boolean,
    image: {
      type: Object as () => ComponentImage,
      default: {
        url: undefined,
        slug: undefined
      }
    }
  },

  data () {
    return {
      dragOver: 0,
      assetDragOverUrl: null! as string,
      apiUrl: process.env.VUE_APP_API_URL || '',
      thumbnail: null! as string
    }
  },

  computed: {
    imageUrl (): string | void {
      return this.image?.url || this.image?.keyVisualUrl
    },
    lazyUrl (): string | void {
      return this.image?.slug ? `${this.apiUrl}/thumbnail/${this.image?.slug}` : this.imageUrl
    }
  },

  mounted () {
    if (this.screenPreview) {
      const url = this.image?.url || this.image?.keyVisualUrl
      if (url) {
        const slug = url.substring(url.lastIndexOf('/') + 1)
        const headers = { authorization: `Bearer ${this.$store.state.auth.token}` }
        const result: any = fetch(`${this.apiUrl}/thumbnail/${slug}`, { headers })
          .then((response) => response.blob())
          .then(data => {
            const reader = new FileReader()
            reader.readAsDataURL(data)
            reader.onloadend = () => {
              this.thumbnail = reader.result as string
            }
          }).catch((err) => {
            console.log(err)
          })
      }
    }
  },

  watch: {
    imageUrl (v) {
      if (!v) return

      this.uploading = false
    }
  },

  methods: {
    dragValidator (e: DragEvent) {
      const draggedAsset = this.$store.state.cms._dragDropAssetData as File

      if (e.dataTransfer?.items.length === 1) {
        const mimeType = e.dataTransfer.items[0].type
        return mimeType.includes('image')
      }

      if (!draggedAsset || !draggedAsset.mime_type.includes('image')) return false
    },
    onDragOver () {
      const draggedAsset = this.$store.state.cms._dragDropAssetData as File

      if (!draggedAsset) return

      this.assetDragOverUrl = draggedAsset.url!
    },
    onDragLeave () {
      this.assetDragOverUrl = null!
      this.dragOver = 0
    },
    onDrop (e: DragEvent) {
      const draggedAsset = this.$store.state.cms._dragDropAssetData as File
      this.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, true)

      if (!draggedAsset) {
        this.getDataTransfer(e).then((url) => {
          if (!url) return
          this.image.url = url
          this.image.slug = url.substring(url.lastIndexOf('/') + 1)
        })
      } else {
        this.image.url = draggedAsset.url!
        this.image.slug = draggedAsset.slug!
      }

      this.assetDragOverUrl = null!
      this.dragOver = 0

      scheduleUpdateQueue()
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="58.811" height="38.04" viewBox="0 0 58.811 38.04">
  <g id="Image_symbol2" transform="translate(1.406 1)">
    <path id="Pfad_3637" data-name="Pfad 3637" d="M22.96,124.413,43.892,98.362a1.631,1.631,0,0,1,2.657,0L58.5,113.35" transform="translate(-22.96 -88.779)" fill="none" stroke="#6d7579" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Pfad_3638" data-name="Pfad 3638" d="M48.3,123.917l13.237-16.661a1.631,1.631,0,0,1,2.659,0l13.349,16.661" transform="translate(-21.546 -88.283)" fill="none" stroke="#6d7579" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <circle id="Ellipse_662" data-name="Ellipse 662" cx="5.091" cy="5.091" r="5.091" transform="translate(37.231)" fill="none" stroke="#6d7579" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>`,
    category: {
      key: 'media'
    },
    layout: {
      disabled: ['typography']
    }
  }
})
