
















import { ContentTree } from '@simpl/cms/types'
import mixins from 'vue-typed-mixins'
import DragDropHandler from '../mixins/DragDropHandler'
import Layout from '../mixins/Layout'

export default mixins(Layout, DragDropHandler).extend({
  name: 'CBody',

  inject: {
    editMode: { default: false }
  },

  props: {
    content: Object as () => ContentTree
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  cms: {
    draggable: false,
    hideInLibrary: true,
    hideDeleteButton: true,
    layout: {
      disabled: ['margin', 'padding', 'size']
    }
  }
})
