











import Vue from 'vue'
import { AudioComponent } from '../types'

export default Vue.extend({
  name: 'AudioPropertyEditor',

  model: {},

  props: {
    value: Object as () => AudioComponent
  },

  computed: {
    valueProxy (): AudioComponent {
      return this.value || {}
    },
    autoplay: {
      get (): boolean | void {
        return this.valueProxy.autoplay
      },
      set (v: boolean) {
        this.setValue('autoplay', v)
      }
    }
  },

  methods: {
    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    }
  }
})
