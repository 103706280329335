








































import Vue from 'vue'
import SplitResizeView from '@simpl/core/components/SplitResizeView.vue'

const presets = [
  [3, 3, 3, 3],
  [4, 4, 4],
  [6, 3, 3],
  [6, 6],
  [8, 4]
]

export default Vue.extend({
  name: 'ColumnsPropertyEditor',

  components: {
    SplitResizeView
  },

  model: {},

  props: {
    value: Array as () => number[]
  },

  data () {
    return {
      presets
    }
  },

  computed: {
    valueProxy: {
      get (): number[] {
        return this.value || [4, 4, 4]
      },
      set (v: number[]) {
        this.$emit('input', v)
      }
    }
  }
})
