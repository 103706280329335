



















































































































































































import Vue from 'vue'
import { updateTargetTexts, debounce } from '@simpl/core/utils'
import { Text } from '@simpl/core/types/graphql'
import { scheduleUpdateQueue } from '../utils/update-queue'
import { rgbToHex } from '@simpl/cms/utils/colors'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

const capitalize = (v: string) => v.charAt(0).toUpperCase() + v.slice(1)

export default Vue.extend({
  name: 'EditableText',

  props: {
    item: {
      type: Object as () => Record<string, any> & { texts: Text[] },
      required: true
    },
    identifier: {
      type: String,
      default: 'display_name'
    },
    singleLine: Boolean,
    placeholder: String
  },

  data () {
    return {
      text: getTextForLanguageWithFallback(this.item, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode, this.identifier),
      showEditor: false,

      availableTextFormats: ['bold', 'italic', 'underline'],
      textAligns: ['left', 'center', 'right'],
      textAlign: 'left',
      textFormats: [] as string[],
      listFormats: ['unordered', 'ordered'],
      listFormat: null! as string,
      textColor: '#f0f' as string | undefined,

      swatches: [
        ...[['#000000'], ['#ffffff']],
        ...Object
          .keys(this.$vuetify.theme.currentTheme)
          .map(key => this.$vuetify.theme.currentTheme[key])
          .filter((x, i, a) => a.indexOf(x) === i)
          .map(v => [v])
      ],

      toolbarPosition: {
        top: 0 as number | string,
        left: 0 as number | string
      },

      preventBlur: false,
      blurTimeout: null! as number
    }
  },

  computed: {
    editorEl (): HTMLElement {
      return this.$refs.content as HTMLElement
    },
    doc (): Document {
      return (this.$refs.content as HTMLElement).ownerDocument
    }
  },

  mounted () {
    const testDiv = document.createElement('div')
    this.$el.ownerDocument!.body
      .querySelector('.v-application')!.appendChild(testDiv)
    const css = window.getComputedStyle(testDiv)
    this.textColor = rgbToHex(css.color)
    testDiv.parentElement!.removeChild(testDiv)

    const toolbar = this.$refs.toolbar as Vue
    this.$el.ownerDocument!.querySelector('.cms-renderer')!.appendChild(toolbar.$el)
  },

  beforeDestroy () {
    const toolbar = this.$refs.toolbar as Vue
    toolbar.$el.parentElement?.removeChild(toolbar.$el)
  },

  methods: {
    capitalize,

    calculateToolbarPosition () {
      const rendererEl = this.$el.ownerDocument!.querySelector('.cms-renderer')!
      const scrollTop = rendererEl.scrollTop
      const scrollHeight = rendererEl.scrollHeight
      const el = this.$el as HTMLElement
      const rect = el.getBoundingClientRect()
      this.toolbarPosition.top = (rect.bottom + scrollTop > scrollHeight - 40)
        ? `${rect.top + scrollTop - 36}px`
        : `${rect.bottom + scrollTop}px`
      this.toolbarPosition.left = `${rect.left}px`
    },

    onFocus () {
      if (this.blurTimeout) {
        window.clearTimeout(this.blurTimeout)
        this.blurTimeout = null!
      }

      this.calculateToolbarPosition()

      this.showEditor = true
      this.readStyles()

      this.$emit('focus')
    },
    onBlur () {
      this.blurTimeout = window.setTimeout(() => {
        if (this.preventBlur) return

        this.showEditor = false
        this.$emit('blur')
      }, 50)
    },
    onInput () {
      const text = this.editorEl.innerHTML
      this.$emit('input', text)

      updateTargetTexts(this.item, text, this.identifier, this.$store.state.cms.currentLanguageCode)
      scheduleUpdateQueue()
    },
    onPaste (e: ClipboardEvent) {
      const paste = (e.clipboardData || (window as any).clipboardData).getData('text')
      const selection = this.doc.getSelection()

      if (!selection || selection.rangeCount < 1) return

      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(paste))

      this.onInput()
    },
    onTextColorChange: debounce(function (this: any, hex: string) {
      if (!this.textColor || (this.textColor.toLowerCase() === hex.toLowerCase())) {
        this.editorEl.focus()
        return
      }

      this.textColor = hex
      this.applyForeColor()
    }, 50),
    applyForeColor () {
      this.execCommand('foreColor', this.textColor)
    },

    checkSingleLine ($e: KeyboardEvent) {
      if (this.singleLine && $e.key === 'Enter') {
        $e.preventDefault()
        $e.stopPropagation()
      }
    },

    showColorPicker ($e: MouseEvent) {
      $e.preventDefault()
      $e.stopPropagation()

      setTimeout(() => {
        this.editorEl.focus()
      }, 50)
    },

    readStyles () {
      this.calculateToolbarPosition()

      Vue.nextTick(() => {
        this.textFormats = this.availableTextFormats.filter((f: string) => this.doc.queryCommandState(f))

        for (const v of this.textAligns) {
          const align = `justify${capitalize(v)}`
          if (this.doc.queryCommandState(align)) {
            this.textAlign = v
          }
        }

        let listFormat = null
        for (const v of this.listFormats) {
          const format = `insert${capitalize(v)}List`
          if (this.doc.queryCommandState(format)) {
            listFormat = v
          }
        }
        this.listFormat = listFormat!
      })
    },

    focus () {
      if (this.doc.activeElement === this.editorEl) {
        return
      }
      this.editorEl.focus()
    },

    execCommand (command: string, val?: string) {
      this.doc.execCommand(command, false, val)
      this.editorEl.focus()

      this.readStyles()
    }
  }
})
