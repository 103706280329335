




























import { ContentTree } from '@simpl/cms/types'
import { Content } from '@simpl/core/types/graphql'
import ui from '@simpl/core/plugins/ui'
import mixins from 'vue-typed-mixins'
import { debounce } from '@simpl/core/utils'
import TrackingPropertyEditor from '../property-editors/TrackingPropertyEditor.vue'
import EvaluationButton from './EvaluationButton.vue'
import { CMSComponentPropertyGroup } from '../index'
import { TrackingOptions } from '../types'
import Trackable from '../mixins/Trackable'
import TrackingVisualizationCreation from '../mixins/TrackingVisualizationCreation'
import WithUserInput from '../mixins/WithUserInput'
import Layout from '../mixins/Layout'
import { TranslateResult } from 'vue-i18n'

export default mixins(
  Layout,
  Trackable,
  TrackingVisualizationCreation,
  WithUserInput
).extend({
  name: 'CRating',

  components: { EvaluationButton },

  inject: {
    rootContent: { default: undefined! as ContentTree }
  },

  props: {
    content: Object as () => ContentTree,
    value: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: 'mdi-star'
    },
    halfIncrements: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    bgColor: {
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: true
    },
    length: {
      type: Number,
      default: 5
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: '8vw'
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    showFeedbackButton: Boolean,
    trackingSettings: Object as () => TrackingOptions
  },

  data () {
    return {
      evaluated: false,
      isOverflowing: false,
      internalValue: 0 as number
    }
  },

  computed: {
    valueProxy: {
      get (): number {
        if (!this.editMode) {
          return this.readValue() || this.internalValue
        }
        return this.content.data!.properties?.value || this.value
      },
      set (v: number | string) {
        if (!this.editMode) {
          this.internalValue = parseFloat(v as string)

          if (!this.showFeedbackButton) {
            this.updateValue()
          }
        } else {
          this.content.data!.properties.value = parseFloat(v as string)
        }
      }
    },
    editMode (): boolean {
      return this.$store.state.cms?.editMode
    },
    editModeReadonly (): boolean {
      const cms = this.$store.state.cms || {}
      return cms.editMode && cms.selectedComponentId !== this.content.id
    },
    evaluationButtonText (): TranslateResult {
      return this.evaluated ? this.$t('cms.exercise.sent') : this.$t('cms.exercise.send')
    }
  },

  mounted () {
    if (this.readValue()) this.setCompleted()
  },

  methods: {
    evaluate () {
      this.evaluated = true
      this.setCompleted()

      this.writeValue(this.internalValue)
    },
    updateValue: debounce(function (this: any) {
      this.writeValue(this.internalValue)
      this.setCompleted(!!this.internalValue)
    }, 10)
  },

  cms: {
    preview () {
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.73 17.42">
  <defs>
    <style>
      .c-rating--a {
        stroke: ${ui.framework.theme.currentTheme.primary};
        stroke-miterlimit: 10;
        fill: ${ui.framework.theme.currentTheme.primary};
      }

      .c-rating--b {
        stroke: gray;
        stroke-miterlimit: 10;
        fill: none;
      }
    </style>
  </defs>
  <polygon class="c-rating--a" points="9.02 1.39 11.48 6.37 16.97 7.16 13 11.04 13.94 16.51 9.02 13.93 4.11 16.51 5.05 11.04 1.07 7.16 6.57 6.37 9.02 1.39"/>
  <polygon class="c-rating--a" points="50.87 1.13 53.32 6.11 58.82 6.91 54.84 10.78 55.78 16.25 50.87 13.67 45.95 16.25 46.89 10.78 42.92 6.91 48.41 6.11 50.87 1.13"/>
  <polygon class="c-rating--a" points="29.95 1.13 32.4 6.11 37.89 6.91 33.92 10.78 34.86 16.25 29.95 13.67 25.03 16.25 25.97 10.78 22 6.91 27.49 6.11 29.95 1.13"/>
  <polygon class="c-rating--b" points="71.79 1.13 74.24 6.11 79.74 6.91 75.76 10.78 76.7 16.25 71.79 13.67 66.88 16.25 67.81 10.78 63.84 6.91 69.33 6.11 71.79 1.13"/>
  <polygon class="c-rating--b" points="92.71 1.13 95.17 6.11 100.66 6.91 96.68 10.78 97.62 16.25 92.71 13.67 87.8 16.25 88.73 10.78 84.76 6.91 90.25 6.11 92.71 1.13"/>
</svg>`
    },
    layout: {
      disabled: ['typography']
    },
    props () {
      const settingsGroup: CMSComponentPropertyGroup = {
        key: 'settings',
        properties: [
          {
            key: 'length',
            type: 'slider',
            min: 3,
            max: 6,
            step: 1,
            labelVertical: false,
            trackingSensitive: true
          }, {
            key: 'halfIncrements',
            type: 'switch',
            trackingSensitive: true
          }, {
            key: 'icon',
            type: 'radio-button-group',
            icons: true,
            buttonWidth: 75,
            values: [
              'mdi-star',
              'mdi-shield',
              'mdi-heart'
            ]
          }, {
            key: 'size',
            type: 'radio-button-group',
            specialLabels: true,
            buttonWidth: 45,
            values: [
              { label: 'XS', value: '3vw' },
              { label: 'S', value: '4vw' },
              { label: 'M', value: '6vw' },
              { label: 'L', value: '8vw' },
              { label: 'XL', value: '9vw' }
            ]
          }
        ]
      }

      const groupTracking: CMSComponentPropertyGroup = {
        key: 'tracking',
        properties: [{
          key: 'trackingSettings',
          type: 'TrackingOptions',
          component: TrackingPropertyEditor
        }]
      }

      return [settingsGroup, groupTracking]
    },
    category: {
      key: 'interactive'
    },
    tracking: {
      parseTrackingForVisualization (tracking: Record<string, any>, content?: Content): any[] {
        const properties = content?.data?.properties ? content.data?.properties : tracking.properties?.properties
        const trackingData = tracking.tracking_data
        const factor = properties.halfIncrements ? 2 : 1
        const arr = new Array(properties.length * factor).fill(0)
        trackingData.map((td: Record<string, any>) => {
          return arr[td.data.value * factor - 1]++
        })
        return arr
      },
      parseTrackingForTable (tracking: Record<string, any>, content?: Content, categories?: string[] | number[]): any[] {
        const arr = [] as any[]
        let participations = 0
        let percentage = 0
        let category
        tracking.seriesData.forEach((data: number) => {
          participations += data
        })
        tracking.seriesData.forEach((data: any, index: number) => {
          category = categories ? categories[index] : index
          percentage = participations && data / participations
          arr.push([category, data, percentage])
        })
        return arr
      }
    },
    visualization: {
      diagramTypes: ['bar', 'line', 'pie', 'donut'],
      getTextForCategories (content: Content): number[] {
        const categories = []
        const properties = content.data.properties
        const halfIncrements = properties.halfIncrements
        const numOfCategories = halfIncrements
          ? properties.length * 2
          : properties.length

        let value = null
        for (let i = 1; i <= numOfCategories; i++) {
          value = halfIncrements ? 0.5 * i : i
          categories.push(value)
        }
        return categories
      }
    }
  }
})
