

























import { ContentTree } from '@simpl/cms/types'
import { getTextForLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import CMSEditableTextWrapper from './CMSEditableTextWrapper'
import Layout from '../mixins/Layout'
import mixins from 'vue-typed-mixins'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

export default mixins(Layout).extend({
  name: 'CTextArea',

  components: {
    CMSEditableTextWrapper
  },

  props: {
    content: Object as () => ContentTree
  },

  data () {
    return {
      editing: false
    }
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    userText (): string {
      if (this.editMode || this.translateMode) {
        return getTextForLanguageWithFallback(this.content, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode, 'text')
      } else {
        return getTextForTemporaryUserLanguage(this.content, 'text')
      }
    },
    selected (): boolean {
      return this.$store.state.cms.selectedComponentId === this.content.id
    }
  },

  methods: {
    onDblClick () {
      if (!this.editMode && !this.translateMode) {
        return
      }

      this.editing = true
      this.$nextTick(() => {
        (this.$refs.editor as any).focus()
      })
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 40">
  <defs>
    <style>
      .a {
        fill: gray;
      }
    </style>
  </defs>
  <rect class="a" y="12" width="97" height="4"/>
  <rect class="a" width="104" height="4"/>
  <rect class="a" y="24" width="104" height="4"/>
  <rect class="a" y="36" width="79" height="4"/>
</svg>`,
    category: {
      key: 'typography'
    },
    stackingOrder: {
      index: 3
    }
  }
})
