























































































import Vue from 'vue'

export default Vue.extend({
  name: 'AudioPlayerToolbar',

  filters: {
    sec2time (value: any, duration: number) {
      const pad = function (num: number) { return ('000' + num).slice(-2) }
      const hasHours = Math.floor(duration / 60 / 60) > 0
      const time: any = parseFloat(value).toFixed(3)
      const hours = Math.floor(time / 60 / 60)
      const minutes = Math.floor(time / 60) % 60
      const seconds = Math.floor(time - minutes * 60)

      return `${hasHours ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(seconds)}`
    }
  },

  props: {
    playing: Boolean,
    paused: Boolean,
    position: Number,
    muted: Boolean,
    duration: Number,
    disabled: Boolean,
    volume: Number
  },

  data: () => ({
    isSeeking: false,
    showVolume: false
  }),

  computed: {
    internalPosition: {
      get (): number { return this.position }
    },
    internalVolume: {
      get (): number { return this.volume },
      set (v: number) {
        this.$emit('update:volume', v)
      }
    },
    volumeIcon (): string {
      if (this.volume === 0) return 'mdi-volume-off'
      if (this.volume > 0 && this.volume < 0.3) return 'mdi-volume-low'
      if (this.volume >= 0.3 && this.volume < 0.7) return 'mdi-volume-medium'
      return 'mdi-volume-high'
    }
  },

  methods: {
    playPause () {
      if (!this.playing || this.paused) {
        this.$emit('update:playing', true)
        this.$emit('update:paused', false)
      } else {
        this.$emit('update:playing', false)
        this.$emit('update:paused', true)
      }
    },

    onSeeking (v: number) {
      if (!this.isSeeking) return

      this.$emit('seeking', v)
    },
    onSeeked (v: number) {
      this.$emit('seeked', v)

      this.isSeeking = false
    }
  }
})
