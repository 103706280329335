











































import VideoPlayer from '@simpl/core/components/videoplayer/VideoPlayer.vue'
import mixins from 'vue-typed-mixins'
import Layout from '../mixins/Layout'
import { VideoComponent } from '../types'
import { ContentTree } from '@simpl/cms/types'
import AssetDragDrop from '../mixins/AssetDragDrop'
import VideoPropertyEditor from '../property-editors/VideoPropertyEditor.vue'

export default mixins(AssetDragDrop, Layout).extend({
  name: 'CVideoPlayer',

  components: { VideoPlayer },

  props: {
    content: Object as () => ContentTree,
    screenPreview: Boolean,
    video: {
      type: Object as () => VideoComponent,
      default: {
        url: undefined,
        slug: undefined,
        autoplay: false,
        hideControls: false
      }
    }
  },

  data () {
    return {
      mediaType: 'video',
      apiUrl: process.env.VUE_APP_API_URL || '',
      thumbnail: null! as string
    }
  },

  computed: {
    src (): string | void {
      return this.video.url
    },
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  mounted () {
    if (this.screenPreview) {
      const url = this.video?.url
      if (url) {
        const slug = url.substring(url.lastIndexOf('/') + 1)
        const headers = { authorization: `Bearer ${this.$store.state.auth.token}` }
        fetch(`${this.apiUrl}/thumbnail/${slug}`, { headers })
          .then((response) => response.blob())
          .then(data => {
            const reader = new FileReader()
            reader.readAsDataURL(data)
            reader.onloadend = () => {
              this.thumbnail = reader.result as string
            }
          }).catch((err) => {
            console.log(err)
          })
      }
    }
  },

  watch: {
    src (v) {
      if (!v) return

      this.uploading = false
    }
  },

  cms: {
    preview: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" style="height: 50px; width: 50px;">
  <g id="Gruppe_415" data-name="Gruppe 415" transform="translate(-19 -11)">
    <circle id="Ellipse_664" data-name="Ellipse 664" cx="24" cy="24" r="24" transform="translate(20 12)" fill="none" stroke="#6d7579" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Pfad_3645" data-name="Pfad 3645" d="M128.829,69.271l-18,9.7.355-20Z" transform="translate(-72.697 -33)" fill="none" stroke="#6d7579" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>`,
    category: {
      key: 'media'
    },
    layout: {
      disabled: ['alignments', 'typography']
    },
    props () {
      return [{
        key: 'settings',
        properties: [{
          key: 'video',
          type: 'VideoComponent',
          component: VideoPropertyEditor
        }]
      }]
    }
  }
})
