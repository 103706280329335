import { render, staticRenderFns } from "./CDropZone.vue?vue&type=template&id=510dc98d&scoped=true&"
import script from "./CDropZone.vue?vue&type=script&lang=ts&"
export * from "./CDropZone.vue?vue&type=script&lang=ts&"
import style0 from "./CDropZone.vue?vue&type=style&index=0&id=510dc98d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510dc98d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
