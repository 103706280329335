


















import mixins from 'vue-typed-mixins'
import { ContentTree } from '@simpl/cms/types'

import CMSComponentsLibraryDialog from '@simpl/cms/components/dialogs/CMSComponentsLibraryDialog.vue'

import ComponentDragDropDefault from '../mixins/DragDropHandler'
import Layout from '../mixins/Layout'

export default mixins(ComponentDragDropDefault, Layout).extend({
  name: 'CColumn',

  inject: {
    editMode: { default: false }
  },

  components: {
    CMSComponentsLibraryDialog
  },

  props: {
    content: Object as () => ContentTree,
    cols: Number
  },

  computed: {
    editMode (): boolean {
      return this.$store.state.cms.editMode
    }
  },

  cms: {
    hideInLibrary: true,
    draggable: false
  }
})
