import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { mapMutations } from 'vuex'
import { UserInput } from '@simpl/cms/store/types'

export default Vue.extend({
  name: 'WithUserInput',

  props: {
    content: Object as () => ContentTree,
    required: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      userInput: {
        id: this.content.id,
        input: { completed: false } as UserInput
      }
    }
  },

  computed: {
    showEvaluation (): boolean {
      return this.$store.getters['module/showTestEvaluation']
    }
  },

  watch: {
    userInput: {
      deep: true,
      handler (v) {
        this.setUserInput(v)
      }
    }
  },

  created () {
    if (this.content.data!.properties!.required) {
      if (!this.$store.state.cms.userInput[this.content.id]) {
        this.setUserInput(this.userInput)
      }

      this.registerActiveComponentId(this.content.id)
    }
  },

  beforeDestroy () {
    this.unregisterActiveComponentId(this.content.id)
  },

  methods: {
    ...mapMutations('cms', ['setUserInput', 'registerActiveComponentId', 'unregisterActiveComponentId']),

    setCompleted (value?: boolean) {
      this.userInput.input.completed = value ?? true
    },
    setCorrect (value: boolean) {
      this.userInput.input.correct = value
    },
    setPoints (value: number) {
      this.userInput.input.points = value
    }
  },

  cms: {
    props () {
      return !this.$store.state.cms.isTest
        ? [{
            key: 'settings',
            properties: [{
              key: 'required',
              type: 'boolean'
            }]
          }]
        : []
    }
  }
})
