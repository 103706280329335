



























import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { DropZone } from '../types'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import onTouchStart from '../utils/handle-clone-element-drag'

export default Vue.extend({
  name: 'CDropZone',

  props: {
    content: Object as () => ContentTree,
    zone: Object as () => DropZone,
    width: [Number, String],
    height: [Number, String],
    instantEvaluation: Boolean
  },

  data () {
    return {
      droppedItemIdentifier: null! as string,
      dragging: false,
      droppedWrong: false,
      correct: false,
      evaluated: false
    }
  },

  computed: {
    draggedItemIdentifier: {
      get (): string | null {
        return this.$store.state.cms.draggedItemIdentifier
      },
      set (v: string | null) {
        this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_IDENTIFIER}`, v)
      }
    },
    editMode (): boolean {
      return this.$store.state.cms.editMode
    },
    showTestEvaluation (): boolean {
      return this.$store.getters['module/showTestEvaluation']
    },
    showEvaluation (): boolean {
      return this.evaluated || this.showTestEvaluation
    },
    element (): HTMLElement {
      return this.$el as HTMLElement
    },
    classes (): any[] {
      return [this.$vuetify.theme.dark ? 'white--text' : 'black--text',
              'pa-2',
              { draggable: this.droppedItemIdentifier && !this.instantEvaluation }]
    },
    styles (): Record<string, any> {
      return {
        'border-color': this.evaluated
          ? this.correct ? this.$vuetify.theme.currentTheme.success : this.$vuetify.theme.currentTheme.error
          : this.droppedWrong
            ? this.$vuetify.theme.currentTheme.error
            : '',
        'border-style': this.evaluated ? 'solid' : 'dashed',
        'overflow-y': 'hidden'
      }
    }
  },

  watch: {
    droppedItemIdentifier (v) {
      if (!v) {
        this.element.setAttribute('draggable', 'false')
      } else {
        this.element.setAttribute('draggable', 'true')
      }
    }
  },

  mounted () {
    this.element.addEventListener('touchstart', this.handleTouchStart)

    this.element.addEventListener('dragstart', this.onDragStart)
    this.element.addEventListener('dragover', this.onDragOver)
    this.element.addEventListener('dragend', this.onDragEnd)
    this.element.addEventListener('drop', this.onDrop)
  },
  beforeDestroy () {
    this.element.addEventListener('touchstart', this.handleTouchStart)

    this.element.removeEventListener('dragstart', this.onDragStart)
    this.element.removeEventListener('dragover', this.onDragOver)
    this.element.removeEventListener('dragend', this.onDragEnd)
    this.element.removeEventListener('drop', this.onDrop)
  },

  methods: {
    getTextForTemporaryUserLanguage,

    evaluate () {
      this.evaluated = true

      this.correct = this.droppedItemIdentifier === this.zone.matchingItemIdentifier

      return this.correct
    },

    handleTouchStart (e: TouchEvent) {
      if (this.element.getAttribute('draggable') !== 'true') return

      onTouchStart(e)
      this.dragging = true
      this.element.removeEventListener('touchstart', this.handleTouchStart)
    },

    onDragStart () {
      this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_DROP_SUCCESSFUL}`, false)
      this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_IDENTIFIER}`, this.droppedItemIdentifier)
    },
    onDragOver (e: DragEvent) {
      e.preventDefault()
      e.stopPropagation()
    },
    onDrop () {
      if (!this.draggedItemIdentifier || this.droppedItemIdentifier === this.draggedItemIdentifier) return

      if (this.draggedItemIdentifier !== this.zone.matchingItemIdentifier && this.instantEvaluation) {
        this.droppedWrong = true
        setTimeout(() => {
          this.droppedWrong = false
        }, 300)

        return
      }

      this.$store.commit(`cms/${MUTATIONS.SET_KICKED_OUT_OF_DROP_ZONE_ITEM}`, this.droppedItemIdentifier)
      this.droppedItemIdentifier = this.draggedItemIdentifier

      this.$store.commit(`cms/${MUTATIONS.SET_DRAGGED_ITEM_DROP_SUCCESSFUL}`, true)
      this.$emit('dropped')
    },
    onDragEnd () {
      this.dragging = false

      this.element.addEventListener('touchstart', this.handleTouchStart)

      if (this.$store.state.cms.draggedItemDropSuccessful) {
        this.droppedItemIdentifier = null!
      }
    }
  }
}
)
