


















































































import Vue from 'vue'
import { SingleMultipleChoiceExerciseOptions } from '../types'

export default Vue.extend({
  name: 'SingleMultipleChoicePropertyEditor',

  model: {},

  props: {
    value: Object as () => SingleMultipleChoiceExerciseOptions
  },

  computed: {
    valueProxy (): SingleMultipleChoiceExerciseOptions {
      return this.value || {}
    },
    isExercise: {
      get (): boolean {
        return this.valueProxy.isExercise || false
      },
      set (v: boolean) {
        this.setValue('isExercise', v)
      }
    },
    isSingleChoice (): boolean {
      return this.valueProxy.type === 'single'
    },
    allAnswers (): Record<string, any>[] {
      return this.valueProxy.allAnswers
    },
    solution: {
      get (): string | string[] {
        return this.valueProxy.solution
      },
      set (v: string | string[]) {
        this.setValue('solution', v)
      }
    },
    points: {
      get (): number | void {
        return this.valueProxy.points
      },
      set (v: string | undefined) {
        this.setValue('points', v ? parseFloat(v) : v)
      }
    },
    moduleIsTest (): boolean {
      return this.$store.state.cms.isTest
    }
  },

  watch: {
    isSingleChoice (v) {
      this.solution = v ? '' : []
    }
  },

  methods: {
    setValue (prop: string, value: any) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })
    }
  }
})
