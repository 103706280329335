var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-single-multiple-choice",class:_vm.layoutClasses,style:([{ pointerEvents: _vm.componentSelected ? 'none' : 'auto' }, _vm.layoutStyles])},[(_vm.isSingleChoice)?_c('v-radio-group',{staticClass:"ma-0 py-1",attrs:{"hide-details":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.internalAnswers),function(answer){return _c('v-radio',{key:answer.id,staticClass:"my-1 pt-0",class:{ disableInput: _vm.editMode },attrs:{"value":answer.id,"readonly":_vm.evaluated},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.editMode || _vm.translateMode)?_c('CMSEditableTextWrapper',_vm._b({},'CMSEditableTextWrapper',{
            identifier: answer.id,
            item: _vm.content,
            placeholder: _vm.$t('cms.singleMultipleChoice.answer')
          },false)):_c('div',{staticClass:"d-flex flex-row"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTextForTemporaryUserLanguage(_vm.content, answer.id))}}),((_vm.evaluated && !_vm.$store.state.module.isTest) || _vm.showEvaluation)?_c('v-icon',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.wrong.indexOf(answer.id) > -1 ? 'mdi-close' : 'mdi-check')+" ")]):_vm._e()],1)]},proxy:true}],null,true)})}),1):_c('div',{staticClass:"my-0 py-1"},_vm._l((_vm.internalAnswers),function(answer){return _c('v-checkbox',{key:answer.id,staticClass:"my-1 pt-0",class:{ 'disable-input': _vm.editMode },attrs:{"readonly":_vm.evaluated,"value":answer.id,"hide-details":"","ripple":!_vm.$vuetify.breakpoint.xs && !_vm.evaluated},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.editMode || _vm.translateMode)?_c('CMSEditableTextWrapper',_vm._b({},'CMSEditableTextWrapper',{
            identifier: answer.id,
            item: _vm.content,
            placeholder: _vm.$t('cms.singleMultipleChoice.answer')
          },false)):_c('div',{staticClass:"d-flex flex-row"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTextForTemporaryUserLanguage(_vm.content, answer.id))}}),((_vm.evaluated && !_vm.$store.state.module.isTest) || _vm.showEvaluation)?_c('v-icon',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.wrong.indexOf(answer.id) > -1 ? 'mdi-close' : 'mdi-check')+" ")]):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}),1),(!_vm.editMode && (_vm.exerciseOptions.isExercise || _vm.showEvaluation))?_c('EvaluationButton',{attrs:{"evaluated":_vm.evaluated || _vm.showEvaluation,"disabled":!_vm.canEvaluate,"correct":_vm.correct},on:{"evaluate":_vm.evaluate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }