import type Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { scheduleUpdateQueue } from './update-queue'
import { getTreeContent, getTreeContentParent } from '@simpl/cms/utils/tree-operations'

export default function moveComponent (
  context: Vue,
  root: ContentTree,
  targetContent: ContentTree,
  id: string | number,
  insertOperation = 'append',
  sibling: HTMLElement & { __vue__: any } | any | null = null
) {
  const oldParent = getTreeContentParent(root, id)!
  const content = getTreeContent(oldParent, id)!

  const oldIndex = oldParent.children.findIndex(c => String(c.id) === String(id))
  oldParent.children.splice(oldIndex, 1)

  if (insertOperation === 'after' && sibling?.__vue__) {
    const siblingId = sibling.__vue__.content?.id
    const insertPos = targetContent.children.findIndex((c) => String(c.id) === String(siblingId))
    targetContent.children.splice(insertPos === -1 ? 0 : insertPos + 1, 0, content)
  } else if (insertOperation === 'before' && sibling?.__vue__) {
    const siblingId = sibling.__vue__.content?.id
    const insertPos = targetContent.children.findIndex((c) => String(c.id) === String(siblingId))
    targetContent.children.splice(insertPos === -1 ? 0 : insertPos, 0, content)
  } else if (insertOperation === 'prepend' || insertOperation === 'before') {
    if (!targetContent.children) context.$set(targetContent, 'children', [])

    targetContent.children.unshift(content)
  } else {
    if (!targetContent.children) context.$set(targetContent, 'children', [])

    targetContent.children.push(content)
  }

  scheduleUpdateQueue()
}
