































import Vue from 'vue'
import AudioPlayerToolbar from './AudioPlayerToolbar.vue'

export default Vue.extend({
  name: 'AudioPlayer',

  components: { AudioPlayerToolbar },

  props: {
    src: String,
    startAt: {
      type: [String, Number],
      default: 0
    },
    userVolume: {
      type: [String, Number],
      default: 1
    },
    autoplay: Boolean
  },

  data () {
    return {
      playing: false,
      paused: false,
      position: 0,
      duration: 0,
      volume: this.userVolume,
      beforeSeek: null as Record<string, any> | null,
      canPlay: false
    }
  },

  computed: {
    audio (): HTMLAudioElement {
      return this.$refs.audio as HTMLAudioElement
    }
  },

  watch: {
    playing (v) {
      if (v && this.position === this.audio.duration) {
        this.resetPosition()
      }
      v ? this.audio.play() : this.audio.pause()
    },
    paused (v) {
      v ? this.audio.pause() : this.audio.play()
    },
    volume (v) {
      this.audio.volume = v
      this.$emit('volumechange', v)
    },
    position (v) {
      if (this.duration) {
        this.$emit('progress', v / this.duration)
      }
    }
  },

  mounted () {
    if (this.autoplay) {
      this.playing = true
    }
  },
  beforeDestroy () {
    this.audio.pause()
    this.audio.removeAttribute('src')
    this.audio.load()
  },

  methods: {
    stop (keepPosition: boolean) {
      this.audio.pause()
      if (!keepPosition) {
        this.resetPosition()
      }
    },
    resetPosition () {
      this.audio.currentTime = 0
      this.position = 0
    },
    seeking (v: number) {
      if (!this.beforeSeek) {
        this.beforeSeek = {
          playing: this.playing,
          paused: this.paused
        }
        this.paused = true
        this.playing = false
      }

      this.audio.currentTime = v
    },
    seeked (v: number) {
      this.audio.currentTime = v

      this.playing = this.beforeSeek!.playing
      this.paused = this.beforeSeek!.paused
      this.beforeSeek = null
    },

    onCanPlay () {
      this.duration = this.audio.duration
      this.position = parseFloat(this.startAt as string) * this.audio.duration
      this.audio.currentTime = this.position
      this.canPlay = true
    },
    onPlay () {
      this.playing = true
      this.paused = false
    },
    onPause () {
      this.playing = false
      this.paused = true
    },
    onTimeUpdate () {
      if (!this.audio) {
        return
      }
      if (this.audio.duration) {
        this.position = this.audio.currentTime
      } else {
        this.position = -1
      }
    },
    onVolumeChange () {
      this.volume = this.audio.volume
    }
  }
}
)
