const RE_RGB = /rgb\(([0-9]+),\s*([0-9]+),\s*([0-9]+)\)/

export const rgbToHex = (v: string) => {
  if (v.startsWith('#')) return v

  const match = RE_RGB.exec(v)
  if (!match) return

  const r = parseInt(match[1]).toString(16).padStart(2, '0')
  const g = parseInt(match[2]).toString(16).padStart(2, '0')
  const b = parseInt(match[3]).toString(16).padStart(2, '0')
  return `#${r}${g}${b}`
}
