import Vue from 'vue'
import { DragDropHandler } from '../utils/drag-drop'
import createComponent from '../utils/create-component'
import moveComponent from '../utils/move-component'
import { ContentTree } from '@simpl/cms/types'
import { MUTATIONS } from '@simpl/cms/store/consts'
import { DragDropComponentData } from '@simpl/cms/store/types'
import cloneComponent from '../utils/clone-component'
import { getTreeContent } from '@simpl/cms/utils/tree-operations'
import uploadAsset from '../utils/upload-asset'

export type ComponentData = {
  tag: string,
  property?: string
} | null

const getComponentData = function (type: string): ComponentData {
  let componentData = null

  switch (type) {
    case 'image/jpeg':
    case 'image/png':
      componentData = {
        tag: 'CImage',
        property: 'image'
      }
      break
    case 'video/mp4':
      componentData = {
        tag: 'CVideoPlayer',
        property: 'video'
      }
      break
    case 'audio/mp4':
    case 'audio/mpeg':
      componentData = {
        tag: 'CAudioPlayer',
        property: 'audio'
      }
      break
    case 'application/json':
      componentData = {
        tag: 'CLottiePlayer'
      }
      break
    default:
      break
  }

  return componentData
}

export default Vue.extend({
  props: {
    content: Object as () => ContentTree
  },

  data () {
    return {
      isComponent: false,
      isFile: false
    }
  },

  computed: {
    _editMode () {
      return this.$store.state.cms.editMode
    },
    dropTarget (): HTMLElement {
      return this.$el as HTMLElement
    }
  },

  async mounted () {
    if (this._editMode) {
      await this.$nextTick()
      this._initDragDropHandler()
    }
  },
  beforeDestroy () {
    const thisAny: any = this
    thisAny.__dragDropHandler__ && this._destroyDragDropHandler()
  },

  methods: {
    dragValidator (): boolean {
      const dragDropData = this.$store.state.cms._dragDropComponentData

      if (dragDropData?.tag) {
        const component = Vue.component(dragDropData.tag)

        if (component) {
          if (dragDropData.move) {
            if (dragDropData.id === this.content.id) return false
            const draggedContent = getTreeContent(this.$store.state.cms.content, dragDropData.id)
            const isAdjacent = draggedContent && !!getTreeContent(draggedContent, this.content.id)
            if (isAdjacent) return false
          }

          const componentData = component.options.cms?.dragDrop || {}

          const deniedParents: string[] = componentData.deniedParents || []

          if (deniedParents.includes(this.$options.name!)) {
            return false
          }

          const allowedParents: string[] = componentData.allowedParents || []

          this.isComponent = allowedParents.length ? allowedParents.includes(this.$options.name!) : true
        }
      }

      this.isFile = this.$store.state.cms._dragDropFileCount === 1

      return this.isComponent || this.isFile
    },

    async onDrop (e: DragEvent, insertOperation?: string, sibling?: HTMLElement | null) {
      if (this.isComponent) {
        this.handleComponentDrop(e, insertOperation, sibling)
        return
      }

      if (this.isFile) {
        await this.handleFileDrop(e, insertOperation, sibling)
      }
    },

    handleComponentDrop (e: DragEvent, insertOperation?: string, sibling?: HTMLElement | null) {
      this.$store.commit(`cms/${MUTATIONS.SET_DROP_SUCCESSFUL}`, true)

      const dragDropData = this.$store.state.cms._dragDropComponentData as DragDropComponentData

      if (!dragDropData.move) {
        const newComponent = createComponent(
          this,
          this.content,
          dragDropData.tag,
          insertOperation, sibling,
          dragDropData.tag === 'CImage' && !dragDropData.data
            ? { image: { url: null, slug: null } }
            : dragDropData.data
        )

        this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, newComponent.id)
      } else {
        if (e?.altKey) {
          cloneComponent(
            this,
            this.$store.state.cms.content,
            this.content,
            dragDropData.id!,
            insertOperation, sibling
          )
        } else {
          if (dragDropData.id === (sibling as any).__vue__.content.id) return

          moveComponent(
            this,
            this.$store.state.cms.content,
            this.content,
            dragDropData.id!,
            insertOperation, sibling
          )
        }
      }

      this.isComponent = false
    },

    async handleFileDrop (e: DragEvent, insertOperation?: string, sibling?: HTMLElement | null) {
      const file = [...Array.from(e.dataTransfer!.files)]?.[0]

      if (!file) return

      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_COUNT}`, 0)

      const componentData = getComponentData(this.$store.state.cms._dragDropFileType)

      if (componentData?.tag) {
        const newComponent = createComponent(
          this, this.content,
          componentData.tag,
          insertOperation, sibling
        )

        const uploadedAsset = await uploadAsset(this, file) as any

        if (componentData.property) {
          newComponent.data!.properties[componentData.property].url = uploadedAsset.data.uploadAsset.url
        } else {
          newComponent.data!.properties.url = uploadedAsset.data.uploadAsset.url
        }
      }

      this.$store.commit(`cms/${MUTATIONS.SET_DRAG_DROP_FILE_TYPE}`, null)
      this.isFile = false
    },

    _initDragDropHandler () {
      (this as any).__dragDropHandler__ = new DragDropHandler(
        this.dropTarget,
        this.dragValidator,
        this.onDrop,
        this.$options.name
      )
    },

    _destroyDragDropHandler () {
      (this as any).__dragDropHandler__.destroy();
      (this as any).__dragDropHandler__ = null
    }
  }
})
